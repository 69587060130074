@use "../global" as *;
@use "../mixin" as *;

.RichButtonBalloon {
    position: absolute;
    bottom: 16px;
    animation: ButtonBalloon 0.8s ease-in-out infinite alternate;
}

  .RichButtonBalloon--gradation-blue {
    position: relative;
    margin: 1.5em 0;
    width: 268px;
    height: 56px;
    font-size: 16px;
    background: $color-blue-gradation;
    border: solid 2px transparent;
    box-sizing: border-box;
    border-radius: $border-radius-sm;
  }

  .RichButtonBalloon__triangle {
    margin: auto;
    overflow: hidden;
    position: absolute;
    width: 36px;
    height: 32px;
    top: -18px;
    left: 32px;

    &:before {
        content: "";
        position : absolute;
        clip-path: polygon(100% 20%, 0 100%, 100% 100%);
        background: $color-blue-gradation;
        width : 28px;
        height : 18px;
    }

    &:after {
        content: "";
        position     : absolute;
        top: 3px;
        left: -2px;
        clip-path: polygon(100% 20%, 0 100%, 100% 100%);
        background: $color-white;
        width: 28px;
        height: 18px;
    }
  }

  .RichButtonBalloon__inner {
    background-color: $color-white;
    border-radius: $border-radius-xs;
    width: 100%;
    height: 100%;
    padding: 7px 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
  }

  .RichButton__caption {
    text-align: right;
    margin-top: 8px;
  }

  .RichButtonBalloon--absolute {
    position: absolute;
    top: 100%;
    margin-top: 0.5em;
    bottom: auto;
  }