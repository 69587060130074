@use "../global" as *;
@use "../mixin" as *;


.RoundBlock {
    position: relative;
}

/*********************************
	RoundBlock--text
*********************************/

.RoundBlock--text {
    .RoundBlockBg {
        padding-top: 54px;
        padding-bottom: 64px;
        display: grid;
        z-index: -1;

        @include res(tb-sm) {
            padding-bottom: 80px;
        }
    }

    .RoundBlock__content {
        padding-top: 45px;
        padding-bottom: 64px;
        min-height: 300px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @include res(tb-sm) {
            min-height: auto;
        }
    }
}

.RoundBlock--text-has-num {
    .RoundBlock__content {
        padding-top: 45px;
        padding-bottom: 32px;

        @include res(tb) {
            padding-bottom: 60px;
        }
    }
}

.RoundBlock--text-no-num {
    .RoundBlock__content {
        padding-top: 24px;
        padding-bottom: 32px;

        @include res(tb) {
            padding-top: 24px;
            padding-bottom: 60px;
        }

    }
}

.RoundBlock--right.RoundBlock--text {
    .RoundBlock__content {
        padding-left: 40px;

        @include res(tb-sm) {
            padding-left: 20px;
        }
    }

    .RoundBlockBg {
        left: -40px;

        &:before{
            left: 0;
        }

        @include res(tb-sm) {
            left: 0px;
        }
    }

}

.RoundBlock--left.RoundBlock--text {

    .RoundBlock__content {
        @include res(tb-sm) {
            padding-right: 20px;
        }
    }

    .RoundBlockBg {
        right: -40px;

        &:before{
            right: 0;
        }

        @include res(tb-sm) {
            right: 0px;
        }
    }

    .RoundBlockNum {
        left: 0;
    }
}

.RoundBlock--even {
    &.RoundBlock--right.RoundBlock--image {
        .RoundBlockBg {
            left: -140px;
            width: calc(100% + 300px);

            @include res(tb-sm) {
                width: calc(100% + 100px);
            }
        }

        .RoundBlockImage {
            @include res(tb-sm) {
                top: -60px;
            }
        }
    }

    &.RoundBlock--left.RoundBlock--image {
        .RoundBlockBg {
            right: -100px;
            width: calc(100% + 260px);

            @include res(tb-sm) {
                width: calc(100% + 100px);
            }
        }

        .RoundBlockImage {
            @include res(tb-sm) {
                top: -40px;
            }
        }
    }
}

.RoundBlock--right.RoundBlock--text-has-num {
    margin-top: 88px;

    @include res(tb) {
        margin-top: 0;
    }
}


/*********************************
	RoundBlock--image
*********************************/

.RoundBlock--image {
    .RoundBlockBg {
        position: absolute;
        top: 64px;
        height: 320px;

        @include res(tb-sm) {
            height: 240px;
            top: 32px;

            .RoundBlockImage {
                position: relative;
            }
        }

        @include res(sp) {
            height: 162px;
        }
    }
}

.RoundBlock--left {
    .RoundBlockBg {
        right: -200px;

        @include res(tb) {
            right: 0;
        }
    }

    .RoundBlockImage {
        right: 60px;

        @include res(tb) {
            right: 0;
        }
    }

    .RoundBlockImage.RoundBlockImage--inset {
        padding-left: 80px;

        @include res(tb-sm) {
            padding-left: 0;
        }
    }
}

.RoundBlock--right {
    .RoundBlockImage {
        left: 80px;

        @include res(tb-sm) {
            left: 0;
        }
    }
}

/*********************************
	RoundBlockBg
*********************************/
.RoundBlockBg {
    display: flex;
    width: calc(100% + 200px);
    height: 100%;
    position: absolute;
    z-index: -1;
    top: 0;
}

.RoundBlockBg--light-gray {
    background:$color-bg-light-gray;
}

.RoundBlockBg--light-blue {
    background:$color-light-blue-transparent;
}

.RoundBlock--left {
    .RoundBlockBg {
        right: 0;
    }

    .RoundBlockBg--light-blue {
        border-radius: $border-RoundBlock-right-top;
    }

    .RoundBlockBg--light-gray {
        border-radius: $border-RoundBlock-left-top;
    }
}

.RoundBlock--right {
    .RoundBlockBg {
        left: 0;
    }

    .RoundBlockBg--light-blue {
        border-radius: $border-RoundBlock-left-top;
    }

    .RoundBlockBg--light-gray {
        border-radius: $border-RoundBlock-right-top;
    }
}


/*********************************
	RoundBlockNum
*********************************/

.RoundBlockNum {
    top: -92px;
    position: absolute;
    display: flex;
    align-items: flex-end;

    @include res(tb) {
        top: -52px;
    }
}

.RoundBlockNum__image {
    @include res(tb) {
        width: 72px;

        img {
            width: 100%;
        }
    }
}

.RoundBlockNum__number {
    margin-left: 16px;
    margin-bottom: -29px;

    @include res(tb) {
        margin-bottom: -16px;
    }

    .Text {
        line-height: 1;

        @include res(tb) {
            font-size: 10rem;
        }
    }
}

.RoundBlock__description {
    margin-top: 16px;
}

.RoundBlock__caption {
    margin-top: 16px;
}


/*********************************
	RoundBlockImage
*********************************/
.RoundBlockImage.RoundBlockImage--bottom {
    bottom: calc(100% - 360px);
}

.RoundBlockImage.RoundBlockImage--bottom-lg {
    bottom: calc(100% - 370px);
}

.RoundBlockImage.RoundBlockImage--inset {
    min-width: 420px;
    max-width: 93%;

    @include res(tb-sm) {
        min-width: 100%;
        max-width: 100%;
    }
}

.RoundBlockImage--pc-absolute {
    position: absolute;

    @include res(tb-sm) {
        position: relative;
    }
}

.RoundBlockImage {
    min-width: 770px;
    max-width: 800px;
    width: 50vw;

    .Image {
        width: 100%;

        img {
            width: 100%;
        }
    }
}

.RoundBlockImage--outset {
    max-width: 700px;
    min-width: 650px;

    @include res(sp) {
        max-width: 750px;
        min-width: 100%;
    }
}

.RoundBlockImage--middle {
    top: 16px;
}


/*********************************
	RoundBlock Step
*********************************/

@include res(tb-sm) {
    .RoundBlock--step {
        margin-top: 16px;
        margin-bottom: 100px;
    }
}

.RoundBlock--right {
    .RoundBlockBg--step {
        top: 40px;
        left: 60px;
        height: 240px;

        @include res(tb-sm) {
            left: 20px;
            bottom: -20px;
            top: auto;
        }

        @include res(sp) {
            height: 160px;
        }
    }
}

.RoundBlockImage.RoundBlockImage--step {
    min-width: 500px;
    max-width: 550px;
    left: 80px;
    bottom: 80px;
    position: relative;

    @include res(tb-sm) {
        min-width: 90%;
        max-width: 90%;
        left: 40px;
        bottom: 0px;
    }

    @include res(sp) {
        min-width: 100%;
        max-width: 100%;
    }
}