@use "../global" as *;
@use "../mixin" as *;


.PageTitle {
    margin-bottom: 32px;
}

.PageTitle--underline {
    padding-bottom: 8px;
    border-bottom: 1px solid $color-text-black;
}

.PageTitle--mb--lg {
    margin-bottom: 64px;
}