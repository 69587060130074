@use "global/variables" as *;
@use "../mixin" as *;

html {
  font-size: 0.625 * 100%;
  font-family: 'Noto Sans JP', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $color-text-black;
  font-display: swap;
}

body {
  height:100vh;
  width: 100%;
  overflow-x: hidden;
}

.Wrapper {
  pointer-events: none;
}

@include res(tb-sm) {
  .Wrapper {
    overflow: hidden;
  }
}

::selection {
  background: $color-select-blue;
  color: inherit;
}