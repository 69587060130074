@use "../global" as *;
@use "../mixin" as *;

.TopMv {
    padding-top: 100px;
    padding-bottom: 32px;
    position: relative;

    @include res(tb-sm) {
        padding-top: 40px;
    }

    @include res(sp) {
        padding-top: 16px;
        padding-bottom: 0px;
    }

}

.TopMvBody {
    position: relative;
    z-index: 1;
}

.TopMVIllustSp {
    display: none;
}

@include res(sp) {
    .TopMVIllustSp {
        display: block;
        margin: 0 -40px;
    }
}

.TopMvText__description-ruby {
    position: absolute;
    left: 8px;
    top: -16px;
}

.TopMvText__title-ruby {
    position: absolute;
    letter-spacing:20px;
    left: 28px;
    top: -8px;
}

.TopMvText__card{
    background-color: rgba(255, 255, 255, 0.6);
    padding: 1rem;
    border-radius: 8px;
    @include text-xl-w-700;
    @include res(pc){
        font-size: 20px;
    }
}

.TopMvText__column {
    width: calc(100% * 5 / 12);
    
    @include res(pc){
        width: calc(100% * 6 / 12);
    }

    @include res(sp){
        width: 100%;
    }
}
