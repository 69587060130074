@use "../global" as *;
@use "../mixin" as *;


.Text {
    position: relative;
    display: block;
    @include text-md;
}

.Text--inline-block {
    display: inline-block;
}

/*********************************
	Size
*********************************/
.Text-xs {
    @include text-xs;
}

.Text-sm {
    @include text-sm;
}

.Text-lg-w-700 {
    @include text-lg-w-700;
}

/*********************************
	Responsive
*********************************/

@include res(sp) {
    .Text-sp-sm {
        font-size: 1.2rem;
    }

    .Text-sp-md {
        font-size: 1.4rem;
    }

    .Text-sp-xl {
        font-size: 2.0rem;
    }

    .Text--sp-left {
        text-align: left!important;
    }
}


/*********************************
	Color
*********************************/

.Text--black {
color: $color-text-black;
}

.Text--blue {

color: $color-blue;
}

.Text--purple {
    color: $color-purple;
}

.Text--gray {
    color: $color-text-gray;
}

.Text--white {
    color: $color-white;
}

.Text--red {
    color: $color-error-red;
}

.Text--gradation-blue {
    @include text-blue-gradation;
}

.Text--gradation-purple {
    @include text-purple-gradation;
}

.Text--white-opacity {
    color: $color-white;
    opacity: 0.64;
}

.Text--transparent-20 {
    opacity: 0.2;
}

/*********************************
	Utility
*********************************/

.Text--center {
    display: block;
    text-align: center;
}

.Text--right {
    display: block;
    text-align: right;
}

.Text--medium {
    font-weight: 500;
}

.Text--bold {
    font-weight: 700;
}

.Text--num {
    font-family: 'Comfortaa';
}

.Text--icon-after {
    display: flex;
    align-items: center;

    .Icon {
        margin-left: 4px;
        display: flex;
        align-items: center;
        position: relative;
        top: 1px;
    }
}

.Text--ls-sm {
    letter-spacing: 0.025em!important;
}

.Text--ls-xs {
    letter-spacing: 0.01em!important;
}

@include res(sp) {
    .Text--sp-left {
        text-align: left!important;
    }
    .Text--sp-center {
        display: block;
        text-align: center;
    }
    .Text--sp-no-br {
        br {
            display: none;
        }
    }
}


.Ruby {
    position: relative;
    background-image: inherit;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-flex;
    margin-top: 16px;

    &:before {
        content: attr(data-ruby);
        position: absolute;
        top: -1em;
        left: 50%;
        transform: translateX(-50%);
        margin: auto;
        white-space: nowrap;
        letter-spacing: 0em;

        @include res(sp) {
            top: -0.7em;
        }
    }

    &.Ruby--right {
        background-size: 200%;
        background-position: 100%;

        &:before {
            background-image: inherit;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-size: 200%;
            background-position: 100%;
        }

        @include res(tb) {
            margin-top: 16px;
            display: inline-block;
        }
    }


}

.Ruby--title-sm {
    &:before {
        @include text-title--md;

        @include res(tb) {
            @include text-title--sm;

        }
    }
}

.Ruby--flat {
    background-image: none;
    -webkit-background-clip: border-box;
    -webkit-text-fill-color: inherit;

    &:before {
        top: -0.7em;
    }

    &.Ruby--right {
        &:before {
            background-image: none;
            -webkit-background-clip: border-box;
            -webkit-text-fill-color: inherit;
        }
    }
}

.Annotation {
    @include text-title--xxs;
    color: $color-text-gray;
    position: relative;
    top: -4px;
}


.BrSp {
    display: none;

    @include res(sp) {
        display: block;
    }
}

.BrPc {
    display: block;

    @include res(sp) {
        display: none;
    }
}

.ShowTextSp {
    display: none;

    @include res(sp) {
        display: inline;
    }
}