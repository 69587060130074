@use "../global" as *;
@use "../mixin" as *;

.Only-sp {
    display: none;

    @include res(sp) {
        display: block;
    }
}

.Only-tb {
    display: none;

    @include res(tb) {
        display: block;
    }
}

.Only-sp-pc {
    display: block;

    @include res(sp) {
        display: none!important;
    }
}

.Only-pc {
    display: block;

    @include res(tb) {
        display: none!important;
    }
}

.Whitespace--pre-wrap {
    white-space: pre-wrap;
}

_::-webkit-full-page-media, _:future, :root .Shadow {
	filter: drop-shadow(0px 0px 16px rgba(0,0,0,0.15));
}