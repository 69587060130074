@use "../global" as *;
@use "../mixin" as *;


.TextLink {
    position: relative;
    @include text-md-w-500;
    color: $color-blue;
    padding-bottom: 1px;
    background: linear-gradient($color-blue,$color-blue) 0 100%/100% 1px no-repeat;
    background-position: 100% 100%;
    transition: background .2s cubic-bezier(.4,.1,.3,1);
    word-break: break-all;

    &:hover {
        background-size: 0 1px;
    }
}

.TextLink--sm {
    font-size: 12px;
}

.Text-sm .TextLink {
    font-size: 12px;
}

.TextLink--error {
    background: linear-gradient($color-error-red,$color-error-red) 0 100%/100% 1px no-repeat;
    background-position: 100% 100%;
    color: $color-error-red;
}

.TextLink--white {
    .Text {
        display: inline-flex;
        padding-bottom: 2px;

    }
}

.TextLink--purple {
    background: linear-gradient($color-purple,$color-purple) 0 100%/100% 1px no-repeat;
    background-position: 100% 100%;
    color: $color-purple;
}

@include res(sp) {
    .TextLink--sp-sm {
        font-size: 12px;
    }
}