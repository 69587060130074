@use "../mixin" as *;

.Row--grid {
    display: grid;
}

.Row--grid--col-3 {
    grid-template-columns: repeat(auto-fit,minmax(140px,calc(32.7% - 8px)));
    justify-content: space-around;
}

.Row--grid--min-160px {
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
}

.Row--grid--min-240px {
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
}

.Row--grid--min-320px {
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
}

.Row--grid--min-500px {
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
}

@include res(sp) {
    .Row--sp-grid--min-auto {
        grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    }

    .Row--grid--sp-col-2 {
        grid-template-columns: repeat(auto-fit,minmax(160px,1fr));
    }
}

.Row--grid-news {
    grid-template-columns: 124px auto 1fr;
    column-gap: 16px;

    .Column {
        flex-shrink: 0;
    }

    @include res(sp) {
        grid-template-columns: 84px auto;
        row-gap: 4px;

        .Column:last-child {
            grid-column: 1 / 3;
            grid-row: 3;
        }
    }
}

.Row--grid-usecase {
    grid-template-columns: auto 1fr;
    column-gap: 16px;

    .Column {
        flex-shrink: 0;
    }

    @include res(sp) {
        grid-template-columns: auto;
        row-gap: 4px;

        .Column:last-child {
            grid-column: 1 / 3;
            grid-row: 3;
        }
    }
}

@include res(tb) {
    .Row--tb-2-column {
        display: grid;
        grid-template-columns:repeat(2,1fr);
    }
}