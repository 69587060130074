@use "../mixin" as *;

.Row {
    display: flex;
    flex: 1;
    position: relative;
}

/*********************************
	Utility
*********************************/

.Row--flex {
    flex-wrap: wrap;

    @include res(sp) {
        >.Column {
            width: 100%;
        }
    }
}

.Row--wrap {
    flex-wrap: wrap;
}

.Row--left {
    justify-content: flex-start;
}

.Row--align-center {
    align-items: center;
}

.Row--center {
    justify-content: center;
}

.Row--space-between {
    justify-content: space-between;
}

.Row--equality {
    >.Column {
        min-width: 0;
        width: 100%;
        position: relative;
        flex: 1;
    }
}

/*********************************
	Column Number
*********************************/
.Row--2 {
    grid-template-columns:1fr 1fr;

    @include res(tb-sm) {
        grid-template-columns:1fr;

        .Row--tb-sm-column {
            flex-direction: column;
            align-items: center;

            >.Column {
                width: 100%;
            }

            >.Column+.Column {
                margin-left: 0;
            }
        }
    }
}

.Row--3 {
    .Column {
        width: calc(100% / 3 );

        &:nth-child(n + 4) {
            margin-top: 40px;
        }
    }
}

/*********************************
	Swap
*********************************/

.Row--swap {
    .Column:first-child {
        order: 2;
    }

    .Column:last-child {
        order: 1;
    }
}

@include res(tb-sm) {
    .Row--tb-sm-swap {
        .Column:first-child {
            order: 1;
        }

        .Column:last-child {
            order: 2;
        }
    }
}



.Row--sp-wrap {
    @include res(sp) {
        flex-wrap: wrap;
        .Column {
            width: 100%;
        }
    }
}