@use '../global/variables' as *;

.Bg--gradation-blue {
    background: $color-blue-gradation;
}

.Bg--gradation-purple {
    background: $color-purple-gradation;
}

.Bg-business {
    
}