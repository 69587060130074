@use "../global" as *;
@use "../mixin" as *;

.ButtonBg {
    width: 100%;
    height: 100%;
    position: absolute;
    left:0;
    top:0;
    z-index: -1;
}

/*********************************
	RichButton
*********************************/

.ButtonBg__outside{
    width: 100%;
    height: 100%;
    position:absolute;
    left:0;
    top:0;
    z-index: 0;

    &:before,&:after {
        content: "";
        display:block;
        width: 100%;
        height: 100%;
        position:absolute;
        left:0;
        top:0;
        z-index: 0;
        transition: 0.2s all ease-out;
    }

    &:before {
        opacity: 1;
        background:$color-white;
    }

    &:after {
        opacity: 0;
    }
}

.ButtonBg__inside {
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    position: absolute;
    left:2px;
    top:2px;
    z-index: 0;
    border-radius: 10px 0 10px 0;
    overflow: hidden;

    &:before,&:after {
        content: "";
        display:block;
        width: 100%;
        height: 100%;
        position:absolute;
        left:0;
        top:0;
        z-index: 0;
        transition: 0.2s all ease-out;
    }

    &:before {
        opacity: 1;
    }

    &:after {
        opacity: 0;
        background:$color-white;
    }
}

/*********************************
	RectButton
*********************************/

.ButtonBg--normal {
    border-radius: 4px;
    overflow: hidden;
    @include button-shadow;
    transition:filter 0.3s ease-out;

    &:before,&:after {
        content: "";
        position: absolute;
        display: block;
        transition: opacity 0.15s ease-out;
    }

    &:before {
        height:100%;
        width: 100%;
        top: 0;
        left: 0;
    }

    &:after {
        opacity: 0;
        background: $color-white;
        content: "";
        height:calc(100% - 4px);
        width: calc(100% - 4px);
        top: 2px;
        left: 2px;
        border-radius: 3px;
    }
}