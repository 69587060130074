@use "../global" as *;
@use "../mixin" as *;

.NotificationBar {
    width: 100%;
    margin-bottom: 40px;
    padding: 24px 0;
}

.NotificationBar__label {
    padding: 4px 16px;
    border-radius: $border-radius-md;
    display: inline-flex;
    align-items: center;

    &:before {
        content: "";
        width: 16px;
        height: 16px;
        display: inline-block;
        margin-right: 8px;
        background-size: cover;
    }
}

.NotificationBar__label + .NotificationBar__body {
    margin-top: 8px;
}

.NotificationBar__body {
    > * + * {
        margin-top: 8px;
    }
}

.NotificationBar--info {
    background-color: $color-light-blue;

    .NotificationBar__label {
        background-color: $color-blue;

        &:before {
            background-image: url(/assets/img/icon/icon-notice-white.svg);
        }
    }
}

.NotificationBar--caution {
    background-color: $color-error-light-red;

    .NotificationBar__label {
        background-color: $color-error-red;

        &:before {
            background-image: url(/assets/img/icon/icon-exclamation-white.svg);
        }
    }
}
