@use "../global" as *;
@use "../mixin" as *;

/* ricoh Header */
.header-ricoh {
  background-color: #eff2f1;
  height: 48px;
  display: flex;
  align-items: center;
  //padding-left: 2rem;
  position: relative;
  z-index: $RicohHeader;
}

.header-ricoh__content {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

@include res(sp) {
  .header-ricoh {
    height: 36px;
    padding-left: 1rem;
  }
  .header-rioch__logo {
    height: 24px;
  }
}
