@use "../global" as *;
@use "../mixin" as *;

.StepItem {
    position: relative;
}

.StepItem:first-child {
    margin-top: 100px;
}

.StepItem + .StepItem {
    margin-top: 100px;

    @include res(tb-sm) {
        margin-top: 0;
    }
}

.StepItem + .StepItem--sp-has-margin {

    @include res(tb-sm) {
        margin-top: 70px;
    }
}

.StepItemNumber {
    position: absolute;
    left: -120px;
    top: -60px;
    height: 100%;

    &.narrow {
        left: -64px;
    }

    @include res(tb) {
        left: -80px;
    }

    @include res(tb-sm) {
        left: -64px;
    }
    @include res(sp) {
        &.narrow {
            left: -30px;
            top: -40px;
        }
    }
}

.is-StepShow {
    .StepItemNumber__label {
    transform: translateY(0);
    opacity: 1;
    }
}

.StepItemNumber__line {
    position: absolute;
    left: calc(50%);
    transform: translateX(-50%);
    top: 50px;
    height:0;
    transition: height 1.25s 0.25s ease-out;

    &:before,&:after {
        display: block;
        content: "";
        width: 2px;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    &:before {
        background : $color-blue-gradation;
    }

    @include res(tb) {
        top: 40px;
    }
}

.StepItemNumber__line--align-left {
    left: 13px;
}

.StepItemNumber__line--color-purple {
    &:before {
        background : $color-purple-gradation;
    }
}

.is-StepShow {
    .StepItemNumber__line {
        height: calc(100% + 56px);

        @include res(tb) {
            height: calc(100% + -48px);
        }
    }
    .StepItemNumber__line--sp-fill-margin {
        @include res(tb) {
            height: calc(100% + 24px);
        }
    }
}

@include res(tb) {
    .StepItem__content {
        // margin-left: -40px;
    }
}

@include res(tb) {
    .StepItemNumber__label .Text {
        font-size: 2.0rem;
    }
}