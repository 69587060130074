@use "../global" as *;
@use "../mixin" as *;

$ImageBoxSize--xs :240px;
$ImageBoxSize--sm :400px;
$ImageBoxSize--md :600px;
$ImageBoxSize--lg :800px;

/*********************************
	Base
*********************************/

.ImageBox {
    margin: 0 auto;
}

.ImageBox__image {
    width: 100%;
    max-width: 240px;
    margin: 0 auto;
    position: relative;

    .Image {
        margin: 0 auto;
    }
}

.ImageBox__image--no-limit {
    max-width: initial;

    @include res(sp) {
        max-width: 270px;
    }
}

.ImageBox_body {
    margin-top: 16px;
}

.ImageBox__caption {
    margin-top: 4px;
}

/*********************************
	Size
*********************************/

.ImageBox--xs {
    max-width: $ImageBoxSize--xs;
}

.ImageBox--sm {
    max-width: $ImageBoxSize--sm;
}

.ImageBox--md {
    max-width: $ImageBoxSize--md;
}

.ImageBox--lg {
    max-width: $ImageBoxSize--lg;
}

.ImageBox--full {
    max-width: 100%;
}

/*********************************
	Utility
*********************************/

.ImageBox--center {
    margin: 0 auto;
}

.ImageBox--vertical-center {
    height: 100%;
    display: flex;
    align-items: center;
}