@use '../global' as *;
@use '../mixin' as *;

.TopMvIllust {
  position: absolute;
  right: -40px;
  top: 50%;
  transform: translateY(calc(-50% - 40px));
  max-width: 60%;
  width: 100%;
  transition: transform 0.2s ease-out, width 0.2s ease-out;

  &.static {
    position: relative;
    top: 0;
    right: 0;
    transform: translate(0);
    max-width: 100%;
  }

  @include res(tb) {
    transform: translateY(calc(-50% - 60px)) translateX(40px);
    max-width: 55%;
  }

  @include res(sp) {
    position: relative;
    top: 0;
    right: 0;
    transform: translate(0);
    max-width: 100%;
  }

  &::before {
    content: '';
    display: block;
    padding-top: 85.2941%;
    width: 100%;
    height: 100%;
  }

  > div {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
  }

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

.TopMvIllustBG {
  position: absolute;
  top: -450px;
  width: 100%;
  height: 2000px;
  left: 0;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  animation: 0.5s BounceIn ease-out forwards;

  background: url(/assets/img/top/mv/wave.svg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  img {
    width: 100%;
  }

  @include res(sp) {
    top: auto;
    bottom: -110px;
    right: 0;
    transform: translate(0);
    height: 440px;
    max-width: 100%;
    background-image: url(/assets/img/top/mv/wave-sp.svg);
  }
}

.TopMvBody {
  opacity: 0;
  visibility: hidden;
}

.is-Loading {
  &.TopMvBody {
    opacity: 0;
    visibility: hidden;
    animation: 0.5s 0.8s downfadein ease-out forwards;

    @include res(sp) {
      animation-delay: 0.5s;
    }
  }

  .MotionIllust__display {
    animation: 0.6s 0.25s display ease-out forwards;
    animation: 0.6s 0.15s BounceIn ease-out forwards;
  }

  .MotionIllust__num {
    animation: 0.6s 0.15s BounceIn ease-out forwards;
  }

  .MotionIllust__comment {
    animation: 0.6s 0.5s display ease-out forwards;
    animation: 0.6s 0.3s BounceIn ease-out forwards;
  }

  .MotionIllust__people {
    animation: 0.6s 0.75s display ease-out forwards;
    animation: 0.6s 0.45s BounceIn ease-out forwards;
  }

  .MotionIllust__peticle {
    animation: 0.6s 1s display ease-out forwards;
    animation: 0.6s 0.6s BounceIn ease-out forwards;
  }
}

@keyframes downfadein {
  0% {
    transform: translateY(-10px);
    opacity: 0;
    visibility: hidden;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }
}

@keyframes BounceIn {
  0% {
    transform: translateY(15px) scale(0.98);
    opacity: 0;
    visibility: hidden;
  }

  60% {
    transform: translateY(-4px) scale(1.005);
    opacity: 1;
    visibility: visible;
  }

  100% {
    transform: translateY(0) scale(1);
    opacity: 1;
    visibility: visible;
  }
}

.particle-1 {
  animation: 2s display ease-in alternate infinite;
}

.particle-2 {
  animation: 2s display ease-in alternate infinite;
}

.display-1-1 {
  animation: 2s display ease-in alternate infinite;
}

.display-1-2 {
  animation: 2.5s display ease-in alternate infinite;
}

.display-2-1 {
  animation: 3s display ease-in alternate infinite;
}

.display-2-2 {
  animation: 3.5s display ease-in alternate infinite;
}

.display-2-3 {
  animation: 2.5s display ease-in alternate infinite;
}

.comment-1 {
  animation: 1s comment ease-in alternate infinite;
}

.comment-2 {
  animation: 1.5s comment ease-in alternate infinite;
}

.comment-1 {
  animation: 1s comment ease-in alternate infinite;
}

.comment-2 {
  animation: 1.5s comment ease-in alternate infinite;
}

.people {
  animation: 1.5s people ease-in alternate infinite;
}

.people-inverse {
  animation: 1.5s people-inverse ease-in alternate infinite;
}

.people-shadow {
  animation: 1.5s peopleShadow ease-in alternate infinite;
  transform-origin: 80% left;
}

.people-shadow-inverse {
  animation: 1.5s peopleShadow-inverse ease-in alternate infinite;
  transform-origin: 80% left;
}

@keyframes display {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(4px);
  }
}

@keyframes comment {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(4px);
  }
}

@keyframes people {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(4px);
  }
}

@keyframes people-inverse {
  0% {
    transform: translateY(3px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes peopleShadow {
  0% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

@keyframes peopleShadow-inverse {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0.5;
  }
}

.MotionIllust {
  padding: 0 40px;
  position: relative;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
  }

  > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    visibility: hidden;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
  }
}

.MotionIllust--download {
  width: calc(100% + 100px);
  margin-left: -50px;

  &::before {
    padding-top: calc(330 / 800 * 100%);
  }
}

.MotionIllust--404 {
  max-width: 800px;
  margin-left: 0px;

  @include res(sp) {
    padding-bottom: 40px;
  }

  &::before {
    padding-top: calc(300 / 800 * 100%);
  }
}

.fadeIn--download {
  opacity: 0;
  visibility: hidden;
  animation: downfadein 0.75s 1.1s forwards;
}

.num-center {
  animation: 2.5s Num-Center ease-in infinite;
}

@keyframes Num-Center {
  0% {
    transform: scale(1, 1) translate(0%, 0%);
  }
  10% {
    transform: scale(0.975, 1.02) translate(0%, 0%);
  }
  20% {
    transform: scale(1.02, 0.95) translate(0%, 2%);
  }

  30% {
    transform: scale(1, 1) translate(0%, 0%);
  }

  100% {
    transform: scale(1, 1) translate(0%, 0%);
  }
}
