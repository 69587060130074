@use "../global" as *;
@use "../mixin" as *;

.Header {
    position: absolute;
    top: 48px;
    left: 0;
    width: 100%;
    z-index: $HeaderMenu;
    transform: translateY(0);
    transition: transform 0.25s ease-out,
    filter 0.25s ease-out,opacity .35s ease-out,visibility 0.25s ease-out ,background-color 0.25s ease-out;

    &.Header--fixed {
        position: fixed;
        top: 0;
        @include header-shadow;
        background-color: $color-white;
        transition: transform .25s ease-out,filter .25s ease-out,opacity .35s ease-out,visibility .25s ease-out,background-color .25s ease-out;

        .Icon--white path {
            fill: $color-text-black;
        }
    }

    &.Header--hide {
        transform: translateY(-80px)!important;
    }

    @include res(sp) {
        top: 36px;
    }

  }

.HeaderContent {
    height: 80px;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    background-color: $color-white;
    transition: background-color 0.3s ease-out;
}

.HeaderLogo {
    height: 80px;
    display: flex;
    align-items: center;
    z-index: $HeaderMenu;

    img {
        height: 28px;
        width: auto;
        transition: opacity 0.3s ease-out,height 0.3s ease-out;

        &.HeaderLogo__white {
            opacity: 0;
            width: 0;
        }

    }
}

/*********************************
	HeaderNavigation
*********************************/

.HeaderNavigation {
    height: 80px;
    display: flex;
    align-items: center;
    margin-left: 16px;
    flex: 1;

    @include res(sp) {
        display: none;
    }
}

.HeaderNavigationList {
    display: flex;
    justify-content: space-between;
    visibility: visible;
    opacity: 1;
    transition: 0.2s opacity ease-out,0.2s visibility ease-out,0.2s transform ease-out;

    @include res(header-sm) {
        visibility: hidden;
        opacity: 0;
        width: 0;
        transform: translateY(-4px);
        margin-left: 0;
    }
}

.HeaderNavigationList__item {
    position: relative;
    align-items: center;
    flex-shrink: 0;

    &:hover {
        color: $color-blue;

        .HeaderNavigationLink:after {
            width: 100%;
            opacity: 1;
        }
    }
}


.HeaderNavigationList__item--full {
    @media screen and (max-width:1166px) {
        display: none;
    }
    .Icon {
        margin-top: 2px;
    }

    &:hover .HeaderNavigationLink {
        .Icon {
            path {
                fill: $color-blue;
            }
            
        }
    }
}
.HeaderNavigationList__item--collapse {
    display: list-item;
    @media screen and (min-width:1165px) {
        display: none;
    }
}


.Header--white {
    .HeaderNavigationList__item--full {
        &:hover .HeaderNavigationLink {
            .Icon {
                path {
                    fill: $color-white;
                }
                
            }
        }
    }
}


.HeaderNavigationLink {
    height: 80px;
    display: inline-flex;
    @include text-title--xs;
    position: relative;
    align-items: center;
    transition: color .15s ease-out,padding .3s ease-out,font-size .3s ease-out;
    padding: 0 12px;
    white-space: nowrap;

    @include res(header-lg) {
        font-size: 1.4rem;
        padding: 0 8px;
    }

    .Icon {
        margin-left: 8px;
        display: inline-flex;
        align-items: center;
    }

    &::after {
        content: "";
        display: block;
        height: 2px;
        width:0;
        opacity: 0.1;
        background-color: $color-blue;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        transition: width 0.2s ease-out,opacity 0.2s ease-out;
    }
}

.HeaderNavigationList__item--has-child {
    position: relative;
    max-width: 120px;
    width: 100%;

    .Icon--under-angle {
        transition: transform .25s ease-out;
    }

    &:hover {
        cursor: pointer;
    }

    &:hover,&.Is-active {
        .HeaderNavigationChildList {
            visibility: visible;
            opacity: 1;
            transform: translateY(0);
        }

        .HeaderNavigationLink {
            color: $color-blue;

            &:after {
                width: 100%;
                opacity: 1;
            }
        }

        .Icon--under-angle {
            transform: rotate(-180deg);

            svg path{
                stroke: $color-blue;
            }
        }
    }
}

.HeaderNavigationChildList {
	position: absolute;
	top:80px;
    background-color: $color-base-light-gray;
    width: 180px;
    opacity: 0;
    visibility: hidden;
    @include header-shadow;
    -webkit-backface-visibility:hidden;
    backface-visibility:hidden;
    transform: translateY(-10px);
	transition: opacity .2s ease-out,visibility .2s ease-out,transform .2s ease-out;
}

.HeaderNavigationChildList__item {
    height: 48px;
}

.HeaderNavigationChildLink {
    @include text-title--xs;
    display: flex;
    min-width: 116px;
    align-items: center;
    height: 48px;
    padding: 8px 16px;
    color: $color-blue;
    transition:0.15s background-color ease-out;
    position: relative;

    .Icon {
        margin-left: 8px;
        margin-top: 2px;
        display: inline-flex;
        align-items: center;
    }

    &:hover {
        background-color: $color-light-blue;
    }
}


/*********************************
	HeaderNavigation
*********************************/

.HeaderButtonArea {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
    transition: 0.2s transform ease-out;

    @include res(header-sm) {
        transform: translateX(-64px);
    }

    @include res(sp) {
        display: none;
    }
}

/*********************************
    Modifier
*********************************/

.Header {
    opacity: 0;
    visibility: hidden;
}

.Header--top {
    transition: transform .5s 0.8s ease-out,filter .25s ease-out,opacity .35s 0.8s ease-out,visibility .25s ease-out,background-color .25s ease-out;
    transform: translateY(-8px);

    &.is-Loading {
        transform: translateY(0);
    }
}

.Header--white,.Header--open {
    .HeaderContent {
        background-color: transparent;
    }

    img {
        &.HeaderLogo__default {
            opacity: 0;
            width: 0;
        }

        &.HeaderLogo__white {
            opacity: 1;
            width: auto;
        }
    }

    .HeaderNavigationList__item {
            color: $color-white;

        .Icon--under-angle {
            svg path{
                stroke: $color-white;
            }
        }

        &:hover .HeaderNavigationLink {
            color: $color-white;
        }
    }

    .HeaderNavigationLink {
        &:after {
            background-color: $color-white;
        }
    }

    .HeaderNavigationList__item--has-child {
        &:hover {
            >a {
                color: $color-white;
            }

        }

        &.Is-active {
            .HeaderNavigationLink {
                color: $color-white;
            }
        }
    }

    .RectButton {
        .ButtonBg {
            background:$color-white;

            &:before,&::after {
                opacity: 0;
            }

            &::after {
                background:$color-blue-gradation;
            }
        }

        .ButtonLabel {
            @include text-blue-gradation;
        }

        &:hover {
            .ButtonBg {
                &:after {
                    opacity: 1;
                }
            }

            .ButtonLabel {
                @include text-background-white;
            }
        }
    }

    .SpMenuButton {
        span{
            background: $color-white;
        }
    }
}

