@use "../global" as *;
@use "../mixin" as *;


.PageMv--business {
    &.js-Loading {
        opacity: 0;
        transition: opacity 0.75s ease-out;
    }

    &.is-Loading {
        opacity: 1;

        .PageMvBg--business:before {
            transform: scale(1);
        }

        .BusinessMvBody {
            transform: translateY(0);
            opacity: 1;
        }
    }
}

.PageMv--business {
    position: relative;
    padding: 64px 0 56px;
    z-index: 1;

    @include res(tb-sm) {
        padding-bottom: 120px;
    }

    @include res(sp) {
        .PageMvTitle .Text {
            font-size: 3.2rem;
            text-align: left;

            br {
                display: none;
            }
        }
    }
}

.PageMv--business-lp {
    padding: 0 0 63px;
    @include res(sp) {
        .PageMvTitle .Text {
            font-size: 2.4rem;
            text-align: left;
            line-height: 1.35;

            br {
                display: block;
            }
        }
        .Ruby--title-sm::before {
            font-size: 1.2rem;
        }
        .PageMvDescription--half {
            width: 100%;
        }
    }
}

.PageMvBg--business {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    overflow: hidden;

    &:before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-image: url("/assets/img/business/business-mv-bg.jpg");
        background-size: cover;
        background-position: center;
        z-index: -1;
        transition: transform 1s ease-out;
        transform: scale(1.075);
    }

    &:after {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: $color-business-mv-gradation;
        opacity: .6;
        z-index: -1;
    }
}

.BusinessMvBody {
    transform: translateY(-10px);
    opacity: 0;
    transition: transform 0.75s ease-out,opacity 0.75s ease-out;
}

.BusinessMvImage {
    position: absolute;
    right: -80px;
    top: 150px;

    @include res(tb-sm) {
        top: auto;
        bottom: -40px;
        right: 0;
        left: 0;
        margin: auto;
    }
}

.BusinessMvImage__item-01,.BusinessMvImage__item-02 {
    opacity: 0;
    transform: rotate(1deg) translateY(4px) scale(0.98);
    transition: transform 0.35s ease-out,opacity 0.35s ease-out;
}

.BusinessMvImage__item-01 {
    width: 400px;
    position: absolute;
    top: 40px;
    right: 60px;
    transition-delay: 0.35s;

    @include res(tb-sm) {
        width: 55%;
        top: 0;
        right: 10px;
    }
}

.BusinessMvImage__item-02 {
    width: 400px;
    position: absolute;
    top: -200px;
    right: 0;
    transition-delay: 0.5s;


    @include res(tb-sm) {
        width: 55%;
        top: 0;
        left: 10px;
    }

    @include res(sp) {
        top: -16px;
    }
}

.is-Loading {
    .BusinessMvImage__item-01 {
        opacity: 1;
        transform: rotate(0deg) translateY(0px)  scale(1);
    }

    .BusinessMvImage__item-02 {
        opacity: 1;
        transform: rotate(0deg) translateY(0px)  scale(1);
    }
}

.BusinessMvImage--02 {
    .BusinessMvImage__item-01 {
        width: 390px;
        top: -40px;
        right: 10px;

        @include res(tb) {
            right: -60px;
        }
        @include res(tb-sm) {
            width: 55%;
            top: 0;
            right: 10px;
        }
    }
    .BusinessMvImage__item-02 {
        top: -270px;
        width: 355px;
        right: 200px;

        @include res(tb) {
            right: 130px;
        }
        @include res(tb-sm) {
            width: 55%;
            top: 0;
            left: 10px;
        }
    }
}


.SectionBg--business {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    overflow: hidden;

    &:before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-image: url("/assets/img/business/business-mv-bg.jpg");
        background-size: cover;
        background-position: center;
        z-index: -1;
        transition: transform 1s ease-out;
    }

    &:after {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: $color-business-mv-gradation;
        opacity: .6;
        z-index: -1;
    }
}

.Section--bg-business-intro {
    background-image: url("/assets/img/business/business-intro-bg.jpg");
    background-size: 100% 535px;
    background-repeat: no-repeat;
    background-position:top;

    @include res(tb-sm) {
        padding-top: 220px;
    }

    @include res(sp) {
        padding-top: 80px;
    }
}

.Section--bg-business-lp-intro {
    @include res(tb-sm) {
        padding-top: 26vw;
    }

    @include res(sp) {
        padding-top: 30vw;
    }
}