@use "../global" as *;
@use "../mixin" as *;


$ButtonSize--md : 320px;
$ButtonSize--lg : 400px;

/*********************************
	Base
*********************************/

.RectButton {
    display: inline-flex;
    position: relative;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    transition: transform 0.2s ease-out;
    width: 100%;
    z-index: 1;

    .ButtonLabel {
        white-space: nowrap;
    }

    &:hover {
        &:not(.RectButton--header,.RectButton--vertical){
            transform: translateY(-2px);
        }

        .ButtonBg {
            &:after {
                opacity: 1;
            }
        }
    }
}

.RectButton--center {
    margin: 0 auto;
    display: flex;
}

/*********************************
	Gradation Blue
*********************************/

.RectButton.RectButton--personal {
    .ButtonBg {
        &:before {
            background: $color-blue-gradation;
        }
    }

    &:hover {
        .ButtonBg {
            @include button-shadow--hover-blue;
        }

        .ButtonLabel {
            @include text-blue-gradation;
        }

        .Icon {
            background: $color-blue-gradation;
        }
    }
}

/*********************************
	Gradation Purple
*********************************/

.RectButton.RectButton--business {
    .ButtonBg {
        &:before {
            background: $color-purple-gradation;
        }
    }

    &:hover {
        .ButtonBg {
            @include button-shadow--hover-purple;
        }

        .ButtonLabel {
            @include text-purple-gradation;
        }

        .Icon {
        background: $color-purple-gradation;
    }
    }
}

/*********************************
	Inverse
*********************************/

.RectButton.RectButton--inverse {
    .ButtonBg {
        background: $color-blue-gradation;

        &::before {
            opacity: 0;
        }

        &::after {
            opacity: 1;
        }
    }

    //Gradation Blue

    &.RectButton--personal {
        .ButtonBg {
            background: $color-blue-gradation;
        }

        .ButtonLabel {
            @include text-blue-gradation;
        }
    }

    //Gradation Purple

    &.RectButton--business {
        .ButtonBg {
            background: $color-purple-gradation;
        }

        .ButtonLabel {
            @include text-purple-gradation;
        }
    }

    &:hover {
        .ButtonBg {
            &::before {
                opacity: 0;
            }

            &::after {
                opacity: 0;
            }
        }

        .ButtonLabel {
            @include text-background-white;
        }
    }
}

.RectButton--icon {
    justify-content: flex-start;

    .RectButton__icon {
        position: absolute;
        right: 20px;
        width: 36px;
        height: 36px;
        transition: 0.15s right ease-out;
    }
}

.RectButton--icon-right-angle {
    .Icon {
        transition: 0.15s transform ease-out;
    }

    &:hover {
        &:after {
            right: 20px;
        }

        .RectButton__icon {
            right: 20px;

            .Icon {
                transform: translateX(4px);
                svg path{
                    stroke: $color-blue;
                    opacity: 0.75;
                }
            }
        }
    }
}

/*********************************
	Size
*********************************/

.RectButton--sm {
    height: 48px;
    width: auto;
    padding: 12px 16px;
}

.RectButton--md {
    max-width: $ButtonSize--md;
    padding: 20px 24px;
}

.RectButton--lg {
    max-width: $ButtonSize--lg;
    width: 100%;
    padding: 20px 24px;
}

.RectButton--width-full {
    width: 100%;
}

.ButtonLabel {
    @include font( $font-size-lg,$line-height,$weight-bold );
    color: $color-white;
}

.ButtonLabel--small {
    @include font( $font-size-sm,$line-height,$weight-medium );
}

.RectButton--has-small-label {
    flex-direction: column;
    align-items: flex-start;
    padding-top: 13px;
    padding-bottom: 13px;
    min-height: 72px;
    justify-content: center;
}

.RectButton--page-top-CTA {
    width: 100%;
    padding: 24px;
}