@use '../global' as *;

.IconButton {
    display: inline-block;
    transition: 0.15s transform ease-out;
    position: relative;
}

.IconButton {
    .Icon--circle {
        &:before {
            transition: 0.15s all ease-out;
        }
    }
}

.IconButton:hover {
    .Icon--circle {
        &:before {
            border-color:$color-white;
            transform-origin: 50% 50%;
            transform: scale(1.15) translate(-50%,-50%) ;
            transform-origin: 0 0;
        }
    }
}