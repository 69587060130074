@use "../global" as *;
@use "../mixin" as *;

.StickyImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 450px);
    z-index: -1;

    >.Row {
        top: 20%;
        position: sticky;
    }

    .RoundBlock--right {
        margin-left: 40px;
        padding-left: 40px;
        margin-top: 40px;
    }

    .RoundBlock--left {
        margin-top: 40px;
    }

    .RoundBlock--right .RoundBlockBg {
        left: 0;
        top: 140px;
    }

    .RoundBlock--right .RoundBlockImage {
        left: -30px;
    }

    .RoundBlock--left .RoundBlockImage {
        right: 50px;
        position: absolute;
    }

    .RoundBlock--left .RoundBlockBg {
        right: 40px;
        top: 120px;
        width: calc(100% + 160px);
    }

    .RoundBlockImage--outset {
        min-width: 800px;
    }

    @include res(tb) {
        display: none;
    }
}

.StickySectionWrap {
    position: relative;

    @include res(tb) {
        display: grid;
        gap: $Gap--3l;
    }

}

.StickySection {
    transition: opacity .2s ease-out,visibility .2s ease-out;
    height: 100%;
    display: grid;
    align-items: center;
    top: 30px;

    >.Column + .Column {
        margin-top: 300px;

        @include res(tb) {
            margin-top: 16px;
        }
    }

    &:first-child {
        padding-top: 0;
    }

    &:last-child {
        padding-bottom:100px;

        @include res(tb) {
            padding-bottom:0;
        }
    }

    @include res(tb) {
        &.StickySection--no-headding {
            margin-bottom: -100px;
    }

}

    &.StickySection--show {
        opacity: 1;
        visibility: visible;
    }


    .RoundBlockBg {
        width: calc(100% + 200px);
        top: auto;
    }

    .RoundBlock--image {
        .RoundBlockBg {
            @include res(tb) {
                height: 280px;
                bottom: 0px;
                margin-top: 32px;
            }

            @include res(sp) {
                height: 192px;
                bottom: -32px;
            }
        }
    }

    .RoundBlockImage {
        min-width: 100%;
        width: calc(100% + 80px);
    }

    .RoundBlock--right {
        .RoundBlock__content {
            padding-left: 0;

            @include res(tb) {
                padding-left: 40px;
            }

            @include res(sp) {
                padding-left: 20px;
            }
        }

        @include res(tb) {
            margin-left: 0;
            padding-left: 0;

            .RoundBlockImage {
                margin: auto;
            }
        }
    }

    .RoundBlock--left {
        margin-right: -80px;
        padding-right: 60px;

        @include res(tb) {
            margin-right: 0;

            .RoundBlockBg {
                right: 0;
                left: auto;
            }

            .RoundBlockImage {
                margin: auto;
            }
        }

        @include res(sp) {
            padding-right: 16px;

            .RoundBlockImage {
                margin-left: -80px;
            }
        }
    }

    .RoundBlock--left.RoundBlock--text {
        margin-top: 50px;

        @include res(tb) {
            margin-top: 0;
        }
    }

    .RoundBlock--left.RoundBlock--text .RoundBlockBg {
        right: 0px;
    }

    .RoundBlock--right.RoundBlock--text .RoundBlockBg {
        left: -80px;
        width: calc(100% + 260px);

        @include res(tb) {
            left: 0px;
        }
    }

    .RoundBlock--right.RoundBlock--text {
        margin-top: 50px;

        @include res(tb) {
            margin-top: 0;
        }
    }

    @include res(tb) {
        height: auto;
        position: relative;
        opacity: 1;
        top: 0;
        gap: $Gap--2l;

        .RoundBlockImage--upper,.RoundBlockImage--middle {
            margin-top: 0;
        }
    }

    @include res(sp) {
        gap: $Gap--2l;
    }
}


@include res(sp) {
    .StickySection--last {
        display: none;
    }
}

.js-StickyImage {
    position: relative;

    .Image {
        position: absolute;
        top: 0;
        left: 0;
        visibility: hidden;
        opacity: 0;
        transition: all 0.5s ease-out
    }

    .Image:first-child {
        visibility: visible;
        opacity: 1;
    }

    .Image.is-show {
        visibility: visible;
        opacity: 1;
    }
}