@use "../global" as *;

.Hr {
    width: 100vw;
    height: 2px;
    display: block;
}

.Hr--purple-gradation {
    background: $color-purple-gradation;
}