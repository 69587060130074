.js-fadeUp{
    transform: translateY(5px) scale(0.99);
    opacity: 0;
    transition: 0.5s all ease-out;

    &.is-fadeUp {
        transform: translateY(0) scale(1);
        opacity: 1;
    }
}

.js-fadeIn {
    opacity: 0;
    transition: 0.75s all ease-out;

    &.is-fadeIn {
        opacity: 1;
    }
}


.js-RoundBlockShow{
    opacity: 0;
    transition: 0.5s all ease-out;

    &:nth-child(1){
        transform: translateX(-5px);
    }

    &:nth-child(2){
        transition-delay: 0.25s;
        transform: translateX(5px);
    }

    &.is-RoundBlockShow {
        transform: translateY(0);
        opacity: 1;

        .RoundBlockNum__image {
            animation : 2s 0.5s bounce infinite;
        }
    }
}

.js-BounceIn {
    opacity: 0;

    &.is-BounceIn {
        opacity: 1;
        animation:bounceIn 0.4s 0.4s ease-out forwards;

    }
}

