@use "../global" as *;
@use "../mixin" as *;

.RichCardWrap {
    display: flex;
    justify-content: space-between;
    margin-top: 32px;
}

.RichCard {
    position: relative;
    padding: 40px 7%;
    border-radius: 60px;
    height: 100%;
    background-color: $color-white;
    @include solid-shadow;

    &:before {
        content: "";
        display: block;
        height: 100%;
        width: 100%;
        @include solid-highlight;
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 60px;
    }
}

.RichCard__image {
    display: block;
    margin: 0 auto;
    max-width: 220px;
    width: 100%;

    &.RichCard__image--page-top {
        margin-top: 24px;
        max-width: 440px;
    }
}

.RichCardBody__heading {
    margin-top: 8px;
    
    &.RichCardBody__heading--page-top {
        margin-top: 0;
    }
}

.RichCardBody__text {
    margin-top: 8px;
    padding: 0 20px;

    &.RichCardBody__text--page-top {
        margin-top: 24px;
    }
}

@include res(tb) {
    .RichCard {
        max-width: 500px;
        margin: 0 auto;
    }
}

.RichCard-CTA {
    // position: relative;
    padding: 24px 32px 40px;
    border-radius: 16px;
    height: 100%;
    background-color: $color-white;
}

.RichCard-CTA__heading {
    padding-bottom: 12px;

    &.RichCard-CTA__heading--blue{
        border-bottom: 2px solid $color-blue;
    }
    
    &.RichCard-CTA__heading--purple{
        border-bottom: 2px solid $color-purple;
    }
}

.RichCard-CTA__body{
    margin-top: 24px;
}