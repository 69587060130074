@use "../mixin" as *;
@use "../global" as *;

.Column {
    min-width: 0;
    position: relative;
}

.Column--shrink {
    flex-shrink: 0;
}

.Column--grow {
    flex-grow: 1;
}

.Column-6 {
    width: calc(100% * 6 / 12);
}

.Column-8 {
    width: calc(100% * 8 / 12);
}

.Column--right {
    margin-left: auto;
}

.Column--news-date {
    min-width: 140px;
    width: 140px;
}

.Column--flex-end {
    justify-content: flex-end;
    display: flex;
}

@include res(sp) {
    .Row--sp-column {
        display: grid;

        >.Column+.Column {
            margin-left: 0;
        }
    }

    .Row--sp-column.Row--center {
        align-items: center;
    }

    .Column-sp-fluid {
        width: 100%;
    }
}

@include res(tb-sm) {
    .Row--tb-sm-column {
            display: grid;
            align-items: center;

            >.Column {
                width: 100%;
            }
    }
}

@include res(tb) {
    .Column-tb-fluid {
        width: 100%;
    }

    .Row--tb-column {
            display: grid;
            align-items: center;

            >.Column {
                width: 100%;
            }
    }

    .Row--tb-column-gap-xl {
       display: grid;
       row-gap: $Gap--xl!important;
    }
}

@include res(pc) {
    .Row--pc-column {
            display: grid;
            align-items: center;

            >.Column {
                width: 100%;
                margin: 0 auto;

                @include res(tb-sm) {
                    width: 100%;
                }
            }
    }
}