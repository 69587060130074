@use "../global" as *;
@use "../mixin" as *;

.ImageCard {
    position: relative;
    border-radius: $border-radius-lg;
    overflow: hidden;
    @include solid-shadow;
}

.ImageCard__image {
    background-color: $color-table-head-gray;
}

.ImageCard__body {
    min-height: 72px;
    padding: 12px;
    display: grid;
    align-items: center;
    position: relative;
    z-index: -1;
    background-color: $color-white;

    &:before {
        content: "";
        display: block;
        height: 100%;
        width: 100%;
        @include solid-highlight;
        position: absolute;
        left: 0;
        top: 0;
        border-radius: $border-radius-lg;
    }
}