@use "../global" as *;
@use "../mixin" as *;

.SpMenu {
    height: 0;
}

.SpNavigation {
    padding-top: 80px;
    padding-bottom: 80px;
    position: relative;
    transform: translateY(calc(-100% - 80px));
    transition: 0.25s transform $ease-out-quad;


    &:before {
        display: block;
        content: "";
        width: 100%;
        height: 100%;
        background-image: url(/assets/img/common/sp-header-menu-bg.svg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        top: 0;
        left: 0;
    }

    @include res(sp) {
        transform: translateY(calc(-100% - 120px));
        padding-bottom: 64px;
    }
}

.SpMenuBg {
    display: block;
    width: 100%;
    min-height: calc(100vh + 40px );
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba($color-text-black,0.16);
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: 0.25s opacity ease-out,0.25s visibility ease-out;
    z-index: -1;
}

.SpNavigationList {
    transition: .2s .2s opacity ease-out;
    visibility: hidden;
    opacity: 0;
}

.SpNavigationList__item {
    height: 80px;
    display: flex;
    align-items: center;
    border-bottom: 1px dashed rgba($color-white,0.4);

    &::before {
        display: none;
    }

    a {
        height: 100%;
        width: 100%;
        display: flex;
    }

    @include res(sp) {
        height: 56px;
    }
}

.SpNavigationChildList__item {
    height: 48px;
    display: flex;
    align-items: center;
}

.SpNavigationList__item .Text {
    color: $color-white;
}

.SpNavigationList__item--has-child {
    max-width: 100%;
    height: auto;
    margin-top: 16px;
    display: block;
}

.SpNavigationList__item--has-child > a >.Text {
    opacity: 0.64;
    font-size: 1.2rem;

    .Icon {
        display: none;
    }
}

.SpNavigationChildList {
    a {
        .Icon path {
            fill: $color-white;
        }
    }

    .Text {
        color: $color-white;
    }
}


.SpMenu--open {
    .SpNavigation {
        transform: translateY(-80px);
    }

    .SpNavigationList {
        opacity: 1;
        visibility: visible;
    }

    .SpMenuBg {
        opacity: 1;
        visibility: visible;
    }
}
