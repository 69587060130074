@use "../global" as *;
@use "../mixin" as *;

.Balloon {
    position: absolute;
    top: -32px;
    z-index: 1;
    animation: ButtonBalloon 0.8s ease-in-out infinite alternate;
}

  .Balloon--gradation-blue {
    background: $color-blue-gradation;
    border: solid 2px transparent;
    box-sizing: border-box;
    border-radius: $border-radius-sm;
  }

  .Balloon__triangle {
    margin: auto;
    overflow: hidden;
    position: absolute;
    width: 36px;
    height: 32px;
    bottom: -14px;
    left: -12px;
    transform: rotate(180deg);
    z-index: -1;

    &:before {
        content: "";
        position : absolute;
        clip-path: polygon(100% 20%, 0 100%, 100% 100%);
        background: $color-blue-gradation;
        width : 16px;
        height : 14px;
    }

    &:after {
        content: "";
        position     : absolute;
        top: 3px;
        left: -2px;
        clip-path: polygon(100% 20%, 0 100%, 100% 100%);
        background: $color-white;
        width: 16px;
        height: 14px;
    }
  }

  .Balloon__inner {
    background-color: $color-white;
    border-radius: $border-radius-xs;
    width: 100%;
    height: 100%;
    padding: 4px 8px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
  }

  .RichButton__caption {
    text-align: right;
    margin-top: 8px;
  }