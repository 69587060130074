@use "../global" as *;
@use "../mixin" as *;

.RoundImage {
    border-radius: $border-radius-md;
    overflow: hidden;
    @include card-shadow;

    .Image {
        width: 100%;

        img {
            width: 100%;
        }
    }
}