@use '../global' as *;
@use "../mixin" as *;

.NumberHighlight {
  display: flex;
  align-items: flex-end;
  gap: 3px;

  &.NumberHighlight--gap-lg {
    gap: 8px;
  }

  @include res(sp) {
    &.NumberHighlight--sp-top-page {
      justify-content: center;
    }
  }
}

.NumberHighlight__color {
  background: $color-purple-gradation;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: flex;
  align-items: flex-end;

  .NumberHighlight__text {
    font-weight: 700;
  }
}

.NumberHighlight__base {
  color: #6A6A6A;

  .NumberHighlight__text {
    font-weight: 500;
  }
}

.NumberHighlight__number {
  font-size: 6.4rem;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 0.1em;
}

.NumberHighlight__text {
  font-size: 4.0rem;
  line-height: 1;

  &.NumberHighlight__text-b-34{
    font-size: 3.4rem;
    font-weight: 700;
    vertical-align: middle;

    @include res(sp) {
      &.NumberHighlight__text-sp-b-34 {
        font-size: 2.4rem;
      }
    }
  }

  &.NumberHighlight__text-b-16{
    font-size: 1.6rem;
    font-weight: 700;
    position: relative;
    top: 5px;
  }
}

