@use "../global" as *;
@use "../mixin" as *;

.Heading-xxs {
    @include text-title--xxs;
}

.Heading-xs {
    @include text-title--xs;
}


.Heading-sm {
    @include text-title--sm;
}

.Heading-md {
    @include text-title--md;

    @include res(sp) {
        font-size: 2.0rem;
    }
}

.Heading-lg {
    @include text-title--lg;

    @include res(sp) {
        font-size: 2.4rem;
    }
}

.Heading-xl {
    @include text-title--xl;

    @include res(sp) {
        font-size: 2.4rem;
    }
}

.Heading-2l {
    @include text-title--2l;
}


@include res(sp) {
    .Heading-sp-14 {
        // @include text-title--xs;
        font-size: 1.4rem;
    }

    .Heading-sp-xs {
        @include text-title--xs;
    }

    .Heading-sp-sm {
        @include text-title--sm;
    }

    .Heading-sp-md {
        @include text-title--md;
    }

    .Heading-sp-lg {
        @include text-title--lg;
    }
}

@include res(tb) {
    .Heading-tb-xs {
        @include text-title--xs;
    }

    .Heading-tb-xs {
        @include text-title--xs;
    }

    .Heading-tb-sm {
        @include text-title--sm;
    }

    .Heading-tb-md {
        @include text-title--md;
    }

    .Heading-tb-lg {
        @include text-title--lg;
    }
}


.Heading--no-margin {
    margin: 0;
}

.Heading--no-padding {
    padding: 0;
}

.Heading--no-pt {
    padding-top: 0;
}

.Heading__sub + .Heading__title {
    margin-top: 0px;
}