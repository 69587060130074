@use "../global" as *;
@use "../mixin" as *;

.ImgSwiper {
    max-width: 500px;
    width: 100%;
    overflow: hidden;
    margin: 40px auto 0;

    @include res(pc) {
        margin-top: -40px;
        max-width: 600px;
    }

    @include res(sp) {
        margin-top: 0px;
        max-width: 320px;
    }
}

.ImgSwiper {
    .swiper-wrapper {
        display: flex;
        transition-timing-function: $ease-out-quad;
    }

    .swiper-slide {
        flex-shrink: 0;
        max-width: 500px;
        width: 100%;
        position: relative;
        transition-property: transform;
        border-radius: $border-radius-md;
        overflow: hidden;

        img {
            width: 100%;
            border-radius: $border-radius-md;
            overflow: hidden;
        }

        @include res(pc) {
            max-width: 600px;
        }

        @include res(tb-sm) {
            max-width: 100%;
        }
    }


    .swiper-button {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 24px;
    }

    .swiper-pagination {
        display: flex;
        justify-content: center;
        overflow: visible;
        margin: 0 24px;
    }

    .swiper-pagination-bullet {
        display: inline-block;
        width: 16px;
        height: 16px;
        border-radius: $border-radius-circle;
        background-color:rgba($color-blue,0.4);
        transition: 0.1s transform ease-out;
        cursor: pointer;

        &:hover {
            transform: scale(1.1);
        }
    }

    .swiper-pagination-bullet + .swiper-pagination-bullet {
        margin-left: 16px;
    }

    .swiper-pagination-bullet-active {
        background-color:$color-blue;
        animation: bounce 0.25s ease-out forwards;
        pointer-events: none;
    }

    .swiper-button-prev,.swiper-button-next {
        width: 24px;
        height: 24px;
        display: block;
        cursor: pointer;
        position: relative;


        &:hover:before {
            transform: scale(1.05);
            background-color: $color-white;
        }

        &:before {
            display: block;
            content: "";
            width: 24px;
            height: 24px;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            border-radius: $border-radius-circle;
            border: 2px solid $color-blue;
            transition: 0.1s transform ease-out,0.1s background ease-out;

        }

        &:after {
            display: block;
            content: "";
            width: 16px;
            height: 16px;
            background-size: cover;
            background-repeat: no-repeat;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
        }
    }

    .swiper-button-prev {
        &:after {
            background-image: url(/assets/img/icon/icon-left-angle-blue.svg);
        }
    }
    .swiper-button-next {
        &:after {
            background-image: url(/assets/img/icon/icon-right-angle-blue.svg);
        }
    }
}