@use "../global" as *;
@use "../mixin" as *;

.AlternateColumn {
    position: relative;

    &+ .AlternateColumn--has-num {
        margin-top: 200px;

        @include res(tb-sm) {
            margin-top: 56px;
        }

        &:last-child {
            padding-bottom: 40px;

            @include res(tb-sm) {
                padding-bottom: 0;
                margin-top: 100px;
            }
        }
    }

    &+ .AlternateColumn--no-num {
        margin-top: 240px;

        @include res(tb) {
            margin-top: 80px;
        }
    }

    .Column:first-child {
        z-index: 1;
    }

    @include res(tb) {
        .Column:first-child {
            z-index: 0;
        }

        .Row {
            width: 100%;
        }
    }

}