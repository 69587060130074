@use "../mixin" as *;

.Main:not(.Main-no-overflow) {
    overflow: hidden;
}

@include res(tb) {
    .Main--tb-overflow {
        overflow: hidden;
    }
}

.Main--has-padding-top {
    padding-top: 80px;
}