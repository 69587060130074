@use "../global" as *;
@use "../mixin" as *;

.RoundBox {
    height: 100%;
    border-radius: $border-radius-lg;
    overflow: hidden;
    @include card-shadow;
    max-width: 500px;
    margin: 0 auto;
}

/*********************************
	Type
*********************************/

.RoundBox--height-equality {
    height: 100%;
}

.RoundBox--no-header {
    padding: 24px 32px;
}

.RoundBox--plain {
    padding: 0;
    box-shadow: none;
    height: auto;
}

.RoundBox--row {
    max-width: 100%;

    @include res(sp) {
        .Row {
            flex-direction: column;
            gap: $Gap--sm;
        }

        .Image {
            width: 160px;
            margin: 0 auto;
        }
    }
}



/*********************************
	RoundBox Header
*********************************/

.RoundBox__header {
    padding: 22px 0;
    text-align: center;
}

.RoundBox__header--sm {
    padding: 4px 0;
}

/*********************************
	RoundBox Body
*********************************/

.RoundBox__body {
    padding: 22px 32px;
    height: 100%;
    background-color: $color-white;
}