@use "../global" as *;
@use "../mixin" as *;

.IndexListBlock {
    background-color: $color-table-head-gray;
    padding: 16px 24px;
}

.IndexList__label {
    padding: 16px 0;
}

.IndexList__item-link {
    display: block;
    padding: 16px 0;
    transition: 0.1s color ease-out;
    @include text-title--xxs;
    color: $color-text-gray;

    &:hover {
        color: $color-blue;
    }
}

.IndexList__item + .IndexList__item {
    border-top: 1px dashed $color-gray;
}