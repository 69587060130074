@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes header-responsive{
  0%{
    transform:translateY(-4px);
    visibility: visible;
  }
  100%{
    transform:translateY(0);
  }
}

@keyframes bggradient{
  0% {
    background-position: 800px 0px;
  }
  50% {
    background-position: 2000px 0;
  }
  100% {
    background-position: 800px 0px;
  }
}

@keyframes wave {
	from {
		transform:rotate(0deg);
	}
	to {
		transform:rotate(360deg);
	}
}

@keyframes arrowmove{
  0%{transform:translateX(0);}
  50%{transform:translateX(3px);}
  0%{transform:translateX(0);}
}

@keyframes arrowmove--infinite{
  0%{transform:translateX(0);}
  20%{transform:translateX(0);}
  30%{transform:translateX(2px);}
  40%{transform:translateX(0px);}
  0%{transform:translateX(0);}
}

@keyframes arrowmove--left{
  0%{transform:translateX(0);}
  50%{transform:translateX(-3px);}
  0%{transform:translateX(0);}
}


@keyframes arrowmove--bottom{
  0%{top:0;}
  50%{top:6px;}
  0%{top:0;}
}

@keyframes StepPathMove{
  0% {
    transform: scale(1, 0);
    transform-origin: 0 0;
  }

  20% {
    transform: scale(1, 0);
    transform-origin: 0 0;
  }

  40% {
    transform: scale(1, 1);
    transform-origin: 0 0;
  }
  40.1% {
    transform: scale(1, 1);
    transform-origin: 0 100%;
  }
  80% {
    transform: scale(1, 0);
    transform-origin: 0 100%;
  }
  100% {
    transform: scale(1, 0);
    transform-origin: 0 100%;
  }
}

@keyframes ButtonBalloon {
  0% {
      transform: translateY(-4px);
  }

  100% {
      transform: translateY(0);
  }
}

@keyframes bounce {
  0% {
     transform: scale(1);
  }
  20% {
    transform: rotate(2deg);
 }
 25% {
  transform: rotate(-2deg);
}

  50% {
     opacity: 1;
     transform: rotate(0deg);
    }
  100% {
     transform: scale(1);

  }
}

@keyframes bounceIn {
  0% {
     opacity: 0;
     transform: scale(.95);
  }
  60% {
     opacity: 1;
     transform: scale(1.025);
  }
  100% {
     transform: scale(1);
     opacity: 1;

  }
}

@keyframes bounce {
  0% {
     transform: scale(1);
  }
  25% {
    transform: scale(0.95);
 }
  80% {
     transform: scale(1.05);
  }
  100% {
     transform: scale(1);

  }
}

.Animation--netcheck {
  animation: netcheck 1.2s linear 0s infinite normal backwards;
}

@keyframes netcheck {
  0% {
    transform: scale(1, 1) translate(0%, 0%);
  }
  7% {
    transform: scale(0.9, 0.9) translate(0%, 5%);
  }
  15% {
    transform: scale(1.1, 0.8) translate(0%, 8%);
  }
  35% {
    transform: scale(0.8, 1.1) translate(0%, -8%);
  }
  55% {
    transform: scale(1.1, 0.9) translate(0%, 5%);
  }
  100% {
    transform: scale(1, 1) translate(0%, 0%);
  }
}