@use "../global" as *;
@use "../mixin" as *;

.Article + .Article {
    margin-top: 56px;
}

.ArticleHeader {
    margin-bottom: 32px;
    padding-bottom: 16px;
    border-bottom: 1px solid $color-text-black;
}

.ArticleHeader--mb-sm {
    margin-bottom: 16px;
}

.ArticleHeader--rich {
    padding-bottom: 24px;
}

.ArticleHeader__date + .ArticleHeader__title {
    margin-top: 32px;
}

.ArticleHeader__description {
    margin-top: 8px;
}

.ArticleFooter {
    margin-top: 80px;
}

.ArticleBody {
    display: grid;
    gap: $Gap--md;

    .ImageBox {
        margin: 32px auto;
    }

    .List--supplement {
        margin-top: 80px;
    }

    .Heading-xs {
        padding-top: 100px;
        margin-top: -84px;
        @include text-title--xs;
    }

    .Heading-md {
        margin-top: 56px;
        padding-bottom: 16px;
    }
}