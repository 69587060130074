@use "../global" as *;
@use "../mixin" as *;

.Icon {
    display: block;
}

.Icon--circle-button {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: $color-blue-gradation;
    display: flex;
    justify-content: center;
    align-items: center;
    @include button-shadow;
    position: relative;

    &::before {
        content: "";
        display: block;
        width: calc(100% - 4px);
        height: calc(100% - 4px);
        border-radius: 50%;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin:auto;
        opacity: 0;
        background-color: $color-white;
    }
}

.Icon--pageback {
    svg {
        position: relative;
        right: 2px;

        path {
            transition: stroke 0.1s ease-out;
        }
    }
}

.Icon--circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    position: relative;

    &:before {
        content: "";
        display: inline-block;
        width: 100%;
        height: 100%;
        background-color: $color-white;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        border-radius: 50%;
        transform-origin: 0% 0%;
    }

    img {
        position: relative;
    }
}

.Icon--text-black {
    path {
        fill:$color-text-black;
    }
}

.Icon--white {
    path {
        fill:$color-white;
    }
}

.Icon--blue {
    path {
        fill:$color-blue;
    }
}

.Icon--right-angle {
    &.Icon-animation {
        animation: arrowmove--infinite 1.5s infinite $ease-out-quart;
    }
}

.Icon--clip {
    mask-repeat: no-repeat;
    mask-position: 0 0;
    mask-size: 100%;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position: 0 0;
    -webkit-mask-size: 100%;
    height: 100%;
    width: 100%;

    &.Icon--share {
        mask-image: url("/assets/images/icon/icon-share.svg");
        -webkit-mask-image: url("/assets/img/icon/icon-share.svg");
    }

    &.Icon--download {
        mask-image: url("/assets/images/icon/icon-download.svg");
        -webkit-mask-image: url("/assets/img/icon/icon-download.svg");
    }

    &.Icon--right-angle {
        mask-image: url("/assets/images/icon/icon-right-angle.svg");
        -webkit-mask-image: url("/assets/img/icon/icon-right-angle.svg");
    }

    &.Icon--bottom-angle {
        mask-image: url("/assets/images/icon/icon-bottom-angle.svg");
        -webkit-mask-image: url("/assets/img/icon/icon-bottom-angle.svg");
    }

    &.Icon--white {
        background-color: $color-white;
    }

    &.Icon--blue-gradation {
        background: $color-blue-gradation;
    }

    &.Icon--purple-gradation {
        background: $color-purple-gradation;
    }
}