@use "../global" as *;
@use "../mixin" as *;

.Table {
    border-collapse: collapse;
    border-spacing: 0;
}

.Table:not(.Table--has-header) {
    .TableRow:first-child {
        .TableItem {
            border-top: 1px solid $color-gray;
        }
    }

    .TableItem--start {
        background-color: $color-table-head-gray;
    }

    .TableItem:last-child {
        padding-right: 0;
    }
}

.TableItem {
    padding:11px 16px;
    border-bottom: 1px solid $color-gray;
}

.TableItem--start {
    width: 225px;

    @include res(sp) {
        width: 120px;
    }
}

.Table--has-header {
    .TableHeader .TableItem {
        background-color: $color-table-head-gray;
        border-bottom: none;
    }
}