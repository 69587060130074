@use "../global" as *;
@use "../mixin" as *;

/*********************************
	Base
*********************************/

.Tab {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 80px;
    gap: 16px;
}

.TabMain {
    padding-top: 54px;
    background-color: $color-white;
    position: relative;
    z-index: 1;
    overflow: hidden;
}

.TabContent {
    opacity: 0;
    height: 0;
    transform: translateY(-5px);
    transition: 0.15s opacity ease-out ,0.15s height ease-out,0.15s transform ease-out;

    &.TabContent--show {
        opacity: 1;
        height: auto;
        transform: translateY(0);
        transition: 0.3s opacity 0.25s ease-out,0.15s height 0.25s ease-out,0.3s transform 0.25s ease-out;
    }
}

.TabButton {
    width: 360px;
    height: 80px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 16px 16px 0 0;
    transition: 0.2s height ease-out,background
    0.2s ease-out,filter
    0.2s ease-out;
    cursor: pointer;
    @include button-shadow;

    &:before {
        display: block;
        content: "";
        height: 0;
        width: calc(100% - 4px);
        background-color: #fff;
        position: absolute;
        bottom: 0px;
        left: 0;
        right: 0;
        opacity: 0;
        margin: auto;
        transition:opacity
        0.2s ease-out;
        border-radius: 14px 14px 0 0;
    }

    &:after {
        display: block;
        content: "";
        height: 2px;
        width:150vw;
        position: absolute;
        bottom: 0;
        opacity: 0;
        left: 50%;
        transform: translateX(-50%);
        transition:0.2s opacity ease;
        z-index: -1;
    }

    &:hover:not(.TabButton--active) {
        height: 88px;
    }
}

@include res(sp) {
    .TabButton {
        height: 48px;

        &:hover:not(.TabButton--active) {
            height: 56px;
        }
    }

    .TabButton .Text {
        font-size: 1.6rem;
    }
}

/*********************************
	Modifier
*********************************/

.TabButton--active {
    pointer-events: none;
    z-index: 1;
    filter: none;

    &:before {
        opacity: 1;
        height: calc(100% - 2px);
    }

    &:after {
        opacity: 1;
    }

    &.TabButton--blue {
        .Text {
            @include text-blue-gradation;
        }
    }

    &.TabButton--purple {
        .Text {
            @include text-purple-gradation;
        }
    }
}


.TabButton--blue {
    background: $color-blue-gradation;

    &::after {
        background: $color-blue-gradation;
    }

    &:hover {
        @include button-shadow--hover-blue;
    }
}

.TabButton--purple {
    background: $color-purple-gradation;

    &::after {
        background: $color-purple-gradation;
    }

    &:hover {
        @include button-shadow--hover-purple;
    }
}
