/*********************************
	Directory
*********************************/
$image-directory:'../img';


/*********************************
	Color
*********************************/

//Base
$color-white: #fff;
$color-text-black: #363636;
$color-text-gray: #6A6A6A;
$color-blue: #1F78CC;
$color-purple: #9B51E0;

//Border
$color-gray: #DBDBDB;

//Background
$color-light-blue: #D9EDF5;
$color-light-blue-transparent: rgba(217,237,245,.56);
$color-bg-light-gray: #F2F2F2;
$color-base-light-gray: #FBFBFB;
$color-base-blue: #A9CDEB;

//Table
$color-table-head-gray: #f8f8f8;

//Card
$color-card-highlight: #FAFAFA;
$color-shadow-gray:rgba(54,54,54,0.32);

//Button
$color-blue-gradation: linear-gradient(60deg, rgba(31,120,204,1) 0%, rgba(21,199,210,1) 100%);
$color-purple-gradation: linear-gradient(60deg, rgba(155,81,224,1) 0%, rgba(224,95,239,1) 100%);


//Business
$color-business-mv-gradation: linear-gradient(135deg, rgba(21,10,143,1) 0%, rgba(21,128,206,.94) 100%);


//Selection
$color-select-blue: rgba($color-blue,0.3);


//Error
$color-error-red : #CC341F;
$color-error-light-red : #FFE8E4;



%font-def {
  font-family: "Noto Sans JP", sans-serif;
  font-weight: 500;
}
%font-def--bold {
  font-family: "Noto Sans JP", sans-serif;
  font-weight: 700;
}



/*********************************
	BorderRadius
*********************************/
$border-radius-xs:2px;
$border-radius-sm:4px;
$border-radius-md:8px;
$border-radius-lg:16px;
$border-radius-circle:100%;
$border-RoundBlock-right-top:12px 60px 12px 60px;
$border-RoundBlock-left-top:60px 12px 60px 12px;


/*********************************
	z-index
*********************************/

$HeaderMenu : 1000;
$RicohHeader: 2000;

/*********************************
	Gap
*********************************/

$Gap--xxs:4px;
$Gap--xs:8px;
$Gap--sm:12px;
$Gap--md:16px;
$Gap--lg:24px;
$Gap--xl:32px;
$Gap--2l:40px;
$Gap--3l:56px;
$Gap--4l:80px;


/*********************************
	FontSize
*********************************/

$font-size-xs : 10;
$font-size-sm : 12;
$font-size-md : 14;
$font-size-lg : 16;
$font-size-xl : 20;


/*********************************
	LineHeight
*********************************/

$line-height : 1.5;


/*********************************
	FontWeight
*********************************/

$weight-normal : 400;
$weight-medium : 500;
$weight-bold : 700;