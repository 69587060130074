@use "../global" as *;
@use "../mixin" as *;

.Wave{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);

    svg {
        position: relative;
        display: block;
        width: calc(100% + 1.3px);
        height: 150px;
    }

    path {
        fill: $color-white;
    }
}

.Wave--blue {
    path {
        fill: $color-light-blue;
    }
}

.Wave--bottom {
    top: inherit;
    bottom: 0;
    transform: rotate(0deg);
}

.Wave--white {
    path {
        fill: $color-white;
    }
}

.Wave--test {
    top: inherit;
    bottom: -200px;
    transform: rotate(0) scaleY(0.5);
    svg {
        height: 630px;

    }
}