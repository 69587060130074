.Spacer {
    display: block;
}

.Spacer--height-6px {
    margin-top: 6px;
}

.Spacer--height-32px {
    margin-top: 32px;
}