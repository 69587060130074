@use "../global" as *;
@use "../mixin" as *;

.StepList {
    counter-reset: count 0;
    display: grid;
    gap: 16px;
}


.StepList__item {
    display: block;
    text-indent: 0;
    padding-left: 28px;
    position: relative;

    &:before {
        content:counter(count);
        counter-increment: count 1;
        position: absolute;
        left: 7px;
        top: 6px;
        color: $color-blue;
        font-weight: bold;
        font-size: 12px;
    }

    &:after {
        content: "";
        display: block;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 1px solid $color-blue;
        position: absolute;
        left: 0;
        top: 4px;
        margin: auto 0;
    }
}

.StepList__item-body {
    margin-left: -28px;
    margin-top: 4px;
}