@use "../global" as *;
@use "../mixin" as *;

.Faq {
    display: grid;
    gap: 32px;

    @include res(sp) {
        gap: $Gap--lg;
    }
}

.FaqAccordion {
    background-color: $color-white;
    border-radius: 8px;
    overflow: hidden;
    display: block;
    @include button-light-shadow--filter;
    transition: 0.15s transform ease-out, 0.2s filter ease-out;
}

.FaqAccordion__heading {
    padding: 16px 84px;
    display: block;
    position: relative;
    cursor: pointer;

    @include res(sp) {
        padding: 16px 56px;
    }

    &:before {
        display: block;
        content: url(../img/top/faq-icon-q.svg);
        position: absolute;
        top: 36px;
        transform: translateY(-50%);
        left: 24px;

        @include res(sp) {
            left: 8px;
            top: 50%;
        }
    }

    &:after {
        display: block;
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        right: 24px;
        transition: 0.25s transform ease-out;
        transform: rotate(0deg);
        height: 36px;
        width: 36px;
        background-image: url(../img/icon/icon-under-angle--gradation-blue.svg);
        background-repeat: no-repeat;
        background-size: cover;

        @include res(sp) {
            height: 24px;
            width: 24px;
        }
    }
}

.FaqAccordion__body {
    display: none;
    padding: 0 84px;
    position: relative;
    background-color: $color-base-light-gray;
    @include text-md;

    @include res(sp) {
        padding-left:52px;
        padding-right:16px;
        @include text-sm;
    }

    &:before {
        display: block;
        content: url(../img/top/faq-icon-a.svg);
        position: absolute;
        transform: translateY(30%);
        left: 24px;

        @include res(sp) {
            left: 8px;
            transform: translateY(8px);
        }
    }

    .TextLink,.Text {
        font-size: inherit;
    }

    .List {
        gap: $Gap--xs;
    }

    .List--num .List__item {
        text-indent: -16px;
        padding-left: 16px;
    }

    .List--num .List__item:before {
        font-size: inherit;
        margin-right: 16px;
    }

}

.FaqAccordion__body-inner {
    padding: 16px 0;

    > * + * {
        margin-top: 8px;
    }
}

/*********************************
	Modifier
*********************************/

.FaqAccordion:hover:not(.FaqAccordion--open) {
    @include button-light-shadow--filter-hover;
    transform: translateY(-2px) translateZ(0);

    .FaqAccordion__heading {
        &:after {
            animation: arrowmove--bottom 0.25s ease-out;
            transition: 0.3s transform ease-out;

        }
    }

    .FaqAccordion__heading .Text {
        color: $color-blue;
    }
}

.FaqAccordion--open {
    @include button-light-shadow--filter-hover;
    transform: translateY(-2px) translateZ(0);

    .FaqAccordion__heading {
        .Text {
            color: $color-blue;
        }

        &:after {
            transform:rotate(-180deg);
        }
    }

}


.FaqAccordion--business {
    .FaqAccordion__heading {
        &:before {
            content: url(../img/top/faq-icon-q-business.svg);
        }
        &:after {
            background-image: url(../img/icon/icon-under-angle--gradation-purple.svg);
        }
    }
    .FaqAccordion__body {
        &:before {
            content: url(../img/top/faq-icon-a-business.svg);
        }
    }
}