/*********************************
	Breakpoint
*********************************/

$bp-pc : 1200;
$bp-tb: 1024;
$bp-header-lg: 1024;
$bp-header-sm: 786;
$bp-tb-sm: 800;
$bp-sp: 600;



@mixin res( $mode :'', $a : 0, $b : 0 ) {

  // SP & TB
  @if $mode == '' {
    @media screen and ( max-width: $bp-sp + px ) {
      @content;
    }

    @media screen and ( max-width: $bp-tb-sm + px ) {
      @content;
    }

    @media screen and ( max-width: $bp-tb + px ) {
      @content;
    }

    @media screen and ( max-width: $bp-header + px ) {
      @content;
    }

    @media screen and ( max-width: $bp-pc + px ) {
      @content;
    }

    // @media screen and ( min-width: $bp-sp + 1px ) and ( max-width: $bp-pc + px ) {
    //   @content;
    // }

  }


  @if $mode == 'sp' {
    @media screen and ( max-width: $bp-sp + px ) {
      @content;
    }
  }

  @if $mode == 'tb-sm' {
    @media screen and ( max-width: $bp-tb-sm + px ) {
      @content;
    }
  }

  @if $mode == 'header-sm' {
    @media screen and ( max-width: $bp-header-sm + px ) {
      @content;
    }
  }

  @if $mode == 'header-lg' {
    @media screen and ( max-width: $bp-header-lg + px ) {
      @content;
    }
  }


  @if $mode == 'tb' {
    @media screen and ( max-width: $bp-tb + px ) {
      @content;
    }
  }


  @if $mode == 'pc' {
    @media screen and ( max-width: $bp-pc + 1px ) {
      @content;
    }
  }


  @else if $mode == '~' {
    @media screen and ( min-width: $a + px ) and ( max-width: $b + px ) {
      @content;
    }
  }


  @else if $mode == '>' {
    @media screen and ( min-width: $a + px ) {
      @content;
    }
  }


  @else if $mode == '<' {
    @media screen and ( max-width: $a + px ) {
      @content;
    }
  }

}

