@use "../mixin" as *;

$ContentsWidth--xs : 600px;
$ContentsWidth--sm : 740px;
$ContentsWidth--md : 920px;
$ContentsWidth--lg : 1020px;
$ContentsWidth--xl : 1200px;

$SidePadding : 40px;
$SidePadding--lg : 90px;
$SidePadding--sp : 4.5%;
$SidePadding--header-sm : 20px;
$SidePadding--step : 120px;
$SidePadding--step-tb : 80px;
$SidePadding--step-sp : 64px;


.ContentsWidth {
  margin: 0 auto;
  padding: 0 $SidePadding;
  box-sizing: border-box;
  position: relative;
  flex: 1;
  width: 100%;
  transition: padding 0.2s ease-out;

  @include res(tb-sm) {
    padding: 0 $SidePadding--sp;
  }
}


.ContentsWidth--xs {
  max-width: calc($ContentsWidth--xs + $SidePadding*2);

  &.ContentsWidth--no-padding {
    max-width: $ContentsWidth--xs;
  }
}

.ContentsWidth--sm {
  max-width: calc($ContentsWidth--sm + $SidePadding*2);

  &.ContentsWidth--no-padding {
      max-width: $ContentsWidth--sm;
  }
}

.ContentsWidth--md {
  max-width: calc($ContentsWidth--md + $SidePadding*2);

  &.ContentsWidth--no-padding {
    max-width: $ContentsWidth--md;
  }
}

.ContentsWidth--lg {
  max-width: calc($ContentsWidth--lg + $SidePadding*2);

  &.ContentsWidth--no-padding {
    max-width: $ContentsWidth--lg;
  }
}

.ContentsWidth--xl {
  max-width: calc($ContentsWidth--xl + $SidePadding*2);
}

.ContentsWidth--padding-lg {
  padding: 0 $SidePadding--lg;

  @include res(sp) {
    padding: 0 $SidePadding--sp;
  }
}

@include res(header-lg) {
  .ContentsWidth--header {
    padding : 0 $SidePadding--header-sm;
  }
}

.ContentsWidth--step {
  padding-left:$SidePadding--step;

  @include res(tb) {
    padding-left:$SidePadding--step-tb;
  }

  @include res(tb-sm) {
    padding-left:$SidePadding--step-sp;
  }
}

.ContentsWidth--step-narrow {
  padding-left: 64px;
  @include res(sp) {
    padding-left: 30px;
    padding-right: 0;
  }
}

.ContentsWidth--no-padding {
  padding: 0;
}