@use "../global" as *;
@use "../mixin" as *;

.CallOut {
    padding: 24px;
}

.CallOut--border-gray {
    @include Border--gray;
}

.CallOut--bg-gray {
    background-color: $color-base-light-gray;
}

.CallOut--bg-light-blue {
    background-color: $color-light-blue-transparent;
}

.CallOut--bg-red {
    background-color: #FFE8E4;
}

.CallOut__body {
    margin-top: 16px;
}

.CallOut--padding-lg {
    padding: 40px 40px 56px;
}


.CallOut--inline-block {
    padding: 16px;
    display: inline-block;
}