@use "../global" as *;
@use "../mixin" as *;

.Footer {
    background: $color-blue-gradation;
    position: relative;
    z-index: -1;
    padding-top: 40px;
}

.Footer--purple {
    background: #9B51E0;//$color-purple-gradation;
}

.Footer--no-wave {
    padding-top: 0;
}

.FooterTop {
     &+ .FooterBody {
        margin-top: 40px;
    }

    &+.FooterBody--mt-lg {
        margin-top: 80px;

        @include res(sp) {
            margin-top: 60px;
        }
    }
}

.FooterBottom {
    margin-top: 40px;
}

.FooterBottom__item {
    padding: 16px 0;
    border-top: 1px solid rgba($color-white,0.24);
}

.js-FooterShow {
    transition: 1s all ease-out;

    &.is-FooterShow {
        transform: translateY(0);

        .Wave {
            transform: translateY(0px) rotate(180deg);
        }
    }
}

.FooterNavigationLink {
    display: inline-flex;
    @include text-title--xs;
    color: $color-white;
    position: relative;
    align-items: center;

    .Icon {
        margin-left: 8px;
        display: inline-flex;
        align-items: center;
    }

    &::after {
        content: "";
        display: block;
        height: 2px;
        width:0;
        background-color: $color-white;
        position: absolute;
        bottom: -4px;
        left: 0;
        transition: width 0.25s ease-out;
    }

    &:hover:after {
        width: 100%;
    }
}