.js-Loading {
    opacity: 0;
    visibility: hidden;
    transition:0.5s opacity ease-out,0.5s visibility ease-out;

    &.is-Loading {
        opacity: 1;
        visibility: visible;
    }
}

