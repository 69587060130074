@use "../global" as *;
@use "../mixin" as *;

.PageMv {
    position: relative;
}

.PageMv--common {
    background: $color-blue-gradation;
    position: relative;
    opacity: 0;
    transition: opacity 0.5s ease-out;
}


.PageMvTitle:not(.PageMvTitle--business) {
    .Text {
        opacity: 0;
        transform: translateY(10px);
        transition: opacity 0.5s ease-out,transform 0.5s ease-out;
    }
}

.is-Loading {
    .PageMvTitle {
        .Text {
            opacity: 1;
            transform: translateY(0);

        }
    }

    .PageMv--common {
        opacity: 1;

    }
}

.PageMvImage--product {
    display: block;
    padding-top: 44%;
    width: calc(100% + 560px);
    background-image: url(/assets/img/product/page-mv-product-img.png);
    background-size: cover;
    background-position: center;
    margin-top: 60px;
    margin-left: -280px;
    margin-bottom: -80px;


    @include res(tb-sm) {
        height: 320px;
    }

    @include res(tb-sm) {
        height: 160px;
        width: calc(100% + 280px);
        margin-left: -140px;
        margin-top: 40px;
        margin-bottom: 0px;
    }
}

@include res(sp) {
    .PageMvTitle {
        text-align: center;
    }
}

.PageMv--h-394px {
    padding: 34px 0 56px;
    height: 394px;
    display: flex;
    align-items: center;
}

@include res(sp) {
    .PageMv--sp-h-326px {
        padding: 34px 0 56px;
        height: 326px;
        display: flex;
        align-items: center;
    }
}



.PageMvDescription--half {
    width: 50%;
}