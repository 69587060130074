@use "../global" as *;
@use "../mixin" as *;

.PriceTableTitle {
    margin-bottom: 56px;

    @include res(sp) {
        margin-bottom: 32px;
    }
}

.PriceTable {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;

    @include res(sp) {
        width: 100%;
    }
}

/*********************************
	Row
*********************************/

.PriceTableRow {
    width: 100%;
    border-bottom: 1px solid $color-gray;
}

.PriceTableRow--no-underline {
    border-bottom: none;
}

.PriceTableRow--top-header {
    border-bottom: none;
}

.PriceTableRow--button-row {
    padding: 16px 0;
}

/*********************************
	Cell
*********************************/

.PriceTableCell {
    padding: 16px 12px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex:1;
    min-height: 64px;
    vertical-align: middle;
}

.PriceTableCell--top-header {
    vertical-align: top;
    border-bottom: none;

    @include res(tb) {
        .Heading-md {
            font-size: 1.8rem;
        }
    }
}

.PriceTableCell--button {
    text-align: center;
}

.PriceTableCell--left-header {
    width: 170px;
    min-width: 170px;
    flex: 0;
    color:$color-text-gray;
    vertical-align: middle;
    padding-left: 0;

    @include res(sp) {
        width: 132px;
        min-width: 132px;
        position: sticky;
        left: 0;
        z-index: 1;
        background-color: $color-white;
        @include button-light-shadow--filter;

    }
}

.PriceTableCell--button-row {
    vertical-align: bottom;
}

.PriceTableCell__caption {
    margin-top: 4px;
}


/*********************************
	Other
*********************************/

.PriceTableBody {
    .PriceTableCell--left-header {
        padding-left: 24px;
    }
}

.PriceTableCaption {
    margin-top: 16px;

    > * + * {
        margin-top: 4px;
    }
}

/*********************************
	Responsive
*********************************/

@include res(sp) {
    .PriceTableWrap {
        overflow: auto;
        white-space: nowrap;
        position: relative;
    }
}