.js-ColorBgShow {
    &:before {
        opacity: 0;
        transition: 0.35s 0.15s all ease-out;
    }

    .ContensInner {
        opacity: 0;
        transition: 0.3s 0.35s all ease-out;
    }

    &.is-ColorBgShow {
        &:before {
            opacity: 1;
        }

        .ContensInner {
            opacity: 1;
        }
    }
}