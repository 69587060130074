@use "../mixin" as *;

$ImageSize--sm:120px;
$ImageSize--md:200px;
$ImageSize--lg:400px;
$ImageSize--full:100%;

.Image {
    &:not(.Image--auto) img,video {
        display: block;
        margin: 0 auto;
        width: 100%;
    }
}

.Image--sm {
    max-width: $ImageSize--sm;
    width: 100%;
}

.Image--md {
    max-width: $ImageSize--md;
    width: 100%;
}

.Image--lg {
    width: $ImageSize--lg;
}

.Image--full {
    width: $ImageSize--full;
}

.Image--center {
    img {
        display: block;
        margin: 0 auto;
    }
}

.Image--page-top-accuracy-icon {
    img {
        display: block;
        margin: 0 auto;
        width: 100%;
    }

    @include res(sp){
        &.Image--sp-page-top-accuracy-icon {
            img {
                width: 24px;
            }
        }
    }
}