@use '../global' as *;
@use "sass:string";

@mixin font( $fs:$font-size-md,$lh:$line-height,$w:$weight-normal ) {
    font-size : string.unquote($fs * 0.1 + 'rem');
    line-height : string.unquote($lh + '');
    font-weight:$w;
}

  /*********************************
      Font
  *********************************/

  @mixin text-xs {
    font-size: 1.0rem;
    font-weight: 500;
    line-height: 1.5;
  }

  @mixin text-sm {
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.5;
  }

  @mixin text-md {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.5;
  }

  @mixin text-md-w-500 {
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.5;
  }

  @mixin text-lg-w-700 {
    font-size: 2.0rem;
    font-weight: 700;
    line-height: 1.5;
  }

  @mixin text-xl-w-700 {
    font-size: 2.4rem;
    font-weight: 700;
    line-height: 1.5;
  }

  @mixin text-title--xxs {
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1.5;
  }

  @mixin text-title--xs {
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 1.5;
  }

  @mixin text-title--sm {
    font-size: 2.0rem;
    font-weight: 700;
    line-height: 1.5;
  }

  @mixin text-title--md {
    font-size: 2.4rem;
    font-weight: 700;
    line-height: 1.5;
  }

  @mixin text-title--lg {
    font-size: 3.2rem;
    font-weight: 700;
    line-height: 1.5;
    letter-spacing: 0.25rem;
  }

  @mixin text-title--xl {
    font-size: 4.0rem;
    font-weight: 700;
    line-height: 1.5;
    letter-spacing: 0.25rem;
  }

  @mixin text-title--2l {
    font-size: 16.0rem;
    font-weight: 700;
    line-height: 1.5;
    letter-spacing: 0.25rem;
  }

  @mixin text-sm-12 {
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.5;
  }

  @mixin text-sm-12-gray {
    font-size: 1.2rem;
    font-weight: 500;
    color: $color-text-gray;
    line-height: 1.5;
  }
  
  @mixin text-b-16-white {
    font-size: 1.6rem;
    font-weight: 700;
    color: $color-white;
  }
  
  @mixin text-b-16-blue {
    font-size: 1.6rem;
    font-weight: 700;
    color: $color-blue;
  }
  
  @mixin text-b-40 {
    font-size: 4.0rem;
    font-weight: 700;
  }

@mixin text-blue-gradation {
    display: inline-block;
    background: $color-blue-gradation;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@mixin text-purple-gradation {
    display: inline-block;
    background: $color-purple-gradation;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@mixin text-background-white {
    background: $color-white;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}