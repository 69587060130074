.js-CardShow{
    transform: translateY(15px);
    opacity: 0;
    transition: 0.5s all ease-out;

    .RichCardBody {
        transition: 0.5s 0.65s all ease-out;
        transform: translateY(5px);
        opacity: 0;
    }

    &.is-CardShow {
        transform: translateY(0);
        opacity: 1;

        .RichCard__image {
            transform: translateY(0);
            opacity: 0;
            animation:bounceIn 0.4s 0.4s ease-out forwards;
        }

        .RichCardBody {
            transform: translateY(0);
            opacity: 1;
        }
    }
}

// @for $i from 1 through 10 {
//     .is-CardShow:nth-child(#{$i}) {
//         transition-delay: $i * 0.12s;
//     }
// }


