@use "../global" as *;
@use "../mixin" as *;

/*********************************
	Base
*********************************/

.RichButtonWrap {
    position: relative;
    max-width: 268px;
    margin: 0 auto;
}

.RichButton {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-end;
    @include button-shadow;
    transition: 0.2s transform ease-out,0.3s filter ease-out;
    width: 100%;

    &:hover {
        transform: translateY(-3px) translateZ(0);

        .RichButtonTop__left {
            &:before,&:after {
                opacity: 1;
            }
        }

        .RichButtonTop__right {
            &:before,&:after {
                opacity: 0;
            }
        }

        &:not(.RichButton--inverse) {
            .ButtonBg__outside {
                &:before {
                    opacity: 0;
                }
            
                &:after {
                    opacity: 1;
                }
            }

            .ButtonBg__inside {
                &:before {
                    opacity: 0;
                }
            
                &:after {
                    opacity: 1;
                }
            }
        }
    }
}

.RichButtonTop {
    border-radius: $border-radius-md $border-radius-md 0 0;
    display: inline-flex;
    height: 24px;
    white-space: nowrap;
    z-index: 1;
    transform: translateZ(0);
}

.RichButtonTop__left {
    padding-left:8px;
    padding-right:12px;
    display: flex;
    align-items: center;
    height: 100%;
    position: relative;

    &:before {
        content: "";
        display: block;
        width: calc(100% - 10px);
        height: 100%;
        background-color: $color-white;
        opacity: 0;
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        border-radius: $border-radius-md 0 0 0;
        transition: .2s opacity ease-out;
    }

    &:after {
        content: "";
        display: block;
        width: 0;
        height: 0;
        position: absolute;
        right: 0;
        border-style: solid;
        border-width: 24px 10px 0 0;
        border-color: #fff transparent transparent transparent;
        z-index: -1;
        opacity: 0;
        transition: .2s opacity ease-out;
    }
}

.RichButtonTop__right {
    position: relative;
    display: flex;
    align-items: center;
    padding-left:4px;
    padding-right:8px;
    height: 100%;
    border-radius: 0 $border-radius-md 0 0;

    &:before {
        content: "";
        display: block;
        width: 0;
        height: 0;
        position: absolute;
        left: -10px;
        border-style: solid;
        border-width: 0 0 24px 10px;
        border-color: transparent transparent #ffffff transparent;
        transition: 0.2s opacity ease-out;
    }

    &:after {
        content: "";
        display: block;
        background-color: $color-white;
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 100%;
        border-radius: 0 $border-radius-md 0 0;
        transition: 0.2s opacity ease-out;
        z-index: -1;
        opacity: 1;
    }

}

.RichButtonMain {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 268px;
    height: 80px;
    padding-left: 32px;
    padding-right: 24px;
    border-radius: 12px 0 12px 0;
    overflow: hidden;
    box-sizing: border-box;
    z-index: 1;

    @include res(sp) {
        width: 268px;
      }
}

.RichButtonMainText__label {
    margin-top: 4px;
}

.RichButtonMain__icon {
    position: absolute;
    right: 24px;
    top: 50%;
    transform: translateY(-50%);
    width: 36px;
    height: 36px;

    .Icon--download-default,.IconMove {
        position: absolute;
        right: 0;
        top: 0;
    }
}

/*********************************
	Personal gradation-blue
*********************************/

.RichButton--personal {
    .RichButtonTop {
        background:$color-blue-gradation;
    }

    .ButtonBg__outside {
        &:after {
            background:$color-blue-gradation;
        }
    }

    .ButtonBg__inside {
        &:before {
            background:$color-blue-gradation;
        }
    }

    &:hover:not(.RichButton--inverse) {
        @include button-shadow--hover-blue;
        transform:translateY(-3px) translateZ(0);

        .RichButtonTop__left {
            .Text,.Text-sm {
                @include text-blue-gradation;
            }
        }

        .RichButtonTop__right {
            .Text,.Text-sm {
                color: $color-white;
                -webkit-text-fill-color: $color-white;
            }
        }

        .RichButtonMainText{
            .Text,.Text-sm {
                @include text-blue-gradation;
            }
        }

        .RichButtonMain__icon {
            .Icon {
                background: $color-blue-gradation;
            }
        }
    }
}

/*********************************
	Business gradation-purple
*********************************/

.RichButton--business {
    .RichButtonTop {
        background:$color-purple-gradation;
    }

    .ButtonBg__outside {
        &:after {
            background:$color-purple-gradation;
        }
    }

    .ButtonBg__inside {
        &:before {
            background:$color-purple-gradation;
        }
    }

    &:hover:not(.RichButton--inverse) {
        @include button-shadow--hover-purple;
        transform:translateY(-3px) translateZ(0);

        .RichButtonTop__left {
            .Text,.Text-sm {
                @include text-purple-gradation;
            }
        }

        .RichButtonTop__right {
            .Text,.Text-sm {
                color: $color-white;
                -webkit-text-fill-color: $color-white;
            }
        }

        .RichButtonMainText{
            .Text,.Text-sm {
                @include text-purple-gradation;
            }
        }

        .RichButtonMain__icon {
            .Icon {
                background: $color-purple-gradation;
            }
        }
    }
}

/*********************************
	no-aside
*********************************/

.RichButton--no-aside {
    align-items: flex-start;
    max-width: 400px;
    width: 100%;

    .RichButtonMain {
        width: 100%;
        height: 72px;
        padding-right: 64px;
    }

    @include res(sp) {
        max-width: 100%;
    }
}

/*********************************
	no-icon
*********************************/
.RichButton--no-icon {
    .RichButtonMain {
        padding: 0 24px;
    }
}


/*********************************
	Inverse
*********************************/
.RichButton--inverse {
    .ButtonBg__outside {
        &:before {
            opacity: 0;
        }

        &:after {
            opacity: 1;
        }
    }

    .ButtonBg__inside {
        &:before {
            opacity: 0;
        }

        &:after {
            opacity: 1;
        }
    }

    &.RichButton--business {
        .Text,.Text-sm {
            @include text-purple-gradation;
        }

        .RichButtonMain__icon {
            .Icon {
                background: $color-purple-gradation;
            }
        }
    }

    &:hover {
        .RichButtonMainText {
            .Text,.Text-sm {
                @include text-background-white;
            }
        }

        .RichButtonMain__icon {
            .Icon {
                background: $color-white;
            }
        }

        .ButtonBg__outside {
            &:before {
                opacity: 1;
            }

            &:after {
                opacity: 0;
            }
        }

        .ButtonBg__inside {
            &:before {
                opacity: 1;
            }

            &:after {
                opacity: 0;
            }
        }
    }
}