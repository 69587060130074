@use '../global' as *;

  @mixin button-shadow {
    filter:drop-shadow(0 1px 4px $color-shadow-gray);
    transform: translateZ(0);
  }

  @mixin button-shadow--hover-blue {
    filter:drop-shadow(0 4px 8px rgba($color-blue,0.25));
    transform: translateZ(0);
  }

  @mixin button-shadow--hover-purple {
    filter:drop-shadow(0 4px 8px rgba($color-purple,0.25));
    transform: translateZ(0);
  }
  
  @mixin button-light-shadow--filter {
    filter:drop-shadow(0 1px 4px rgba(16,24,40,0.06));
    transform: translateZ(0);
  }
  
  @mixin button-light-shadow--filter-hover {
    filter:drop-shadow(0 5px 10px rgba($color-blue,0.15));
    transform: translateZ(0);
  }

  @mixin header-shadow {
    filter:drop-shadow(0 1px 3px rgba($color-shadow-gray,0.2));
    transform: translateZ(0);
  }

  @mixin sticky-shadow {
    filter:drop-shadow(0 1px -3px rgba($color-shadow-gray,0.2));
    transform: translateZ(0);
  }
  
  @mixin solid-shadow {
    box-shadow: 4px 4px 0 -1px rgb(#000 , 10%);
  }
  
  @mixin solid-highlight {
    box-shadow: 4px 4px 0 -1px $color-card-highlight inset;
  }
  
  @mixin card-shadow {
    box-shadow: 0px 0px 20px rgba(40,16,16,0.16);
    transform: translateZ(0);
  }