@use "../global" as *;
@use "../mixin" as *;

.StickyArea {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 1;
    transform: translateX(100%);
    height: calc(100vh - 80px);
    transition: transform 0.2s ease-out;

    @include res(sp) {
        left: 0;
        background-color: $color-white;
        width: 100vw;
        height: auto;
        @include header-shadow;
        transform: translateX(0) translateY(100%) translateZ(0);
    }
}

.StickyArea--show {
    transform: translate(0);
}

.StickyArea__content {
    @include grid($Gap--md);
    position: relative;
    top: 50%;
    transform: translateY(-50%);

    @include res(sp) {
        grid-template-columns: 1fr 1fr;
        gap: $Gap--sm;
        padding: 12px 16px;
        top: 0;
        transform: translateY(0);
    }
}

.StickyButton {
    display: block;
    width: 64px;
    border-radius: $border-radius-md 0 0 $border-radius-md;
    overflow: hidden;
    position: relative;
    right: -5px;
    transition: transform 0.2s ease-out;

    @include res(sp) {
        width: auto;
        border-radius: $border-radius-md;
        right: 0;
        @include button-shadow--hover-blue
    }

    &:hover {
        transform: translateX(-5px);

        @include res(sp) {
            transform: translateX(0);
        }
    }

    .ButtonBg {
        border-radius: $border-radius-md 0 0 $border-radius-md;

        @include res(sp) {
            border-radius: $border-radius-md;
        }

        &::after {
            border-radius: 6px 0 0 0;
            height: calc(100% - 4px);
            width: calc(100% - 4px);
            top: 4px;
            left: 4px;

            @include res(sp) {
                border-radius: 6px 6px 0 0;
                top: 2px;
                left: 2px;
            }
        }
    }

    .StickyButtonInner {
        @include grid(center,center);
        justify-content: center;
        overflow: hidden;
        height: calc(100% - 4px);
        width: 100%;
        grid-auto-columns: 1fr;
        align-items: center;

        @include res(sp) {
            grid-template-columns: 1fr;
        }

    }

    .StickyButton__aside {
        padding: 8px 0;
        @include grid(center,center);
        @include font($font-size-xs,$line-height,$weight-bold);
        color: $color-white;

        @include res(sp) {
            padding-right: auto;
            padding: 4px 8px;

            br {
                display: none;
            }
        }
    }
}

.StickyButtonBody {
    padding: 8px 16px 12px;
    width: 100%;
    position: relative;
    @include grid(center,center);

    @include res(sp) {
        display: flex;
        justify-content: center;
    }

    &:before {
        display: block;
        content: "";
        width: calc(100% - 4px);
        height: calc(100% - 4px);
        padding-right: 12px;
        background-color: $color-white;
        position: absolute;
        top: 0;
        right: 0;
        border-radius: 0 0 0 5px;
        opacity: 1;
        z-index: 0;
        transform: translateZ(0);
        transition: opacity 0.15s ease-out;

        @include res(sp) {
            border-radius: 0 0 6px 6px;
            padding-right: 0;
            width: calc(100% - 8px);
            right: auto;
        }
    }

    @include res(sp) {
        padding: 4px 8px 8px;
    }
}

.StickyButton__label {
    position: relative;
    @include flex(center,center,$Gap--xxs);
    @include font($font-size-lg,$line-height,$weight-bold);
    writing-mode: vertical-rl;
    text-orientation: upright;
    transform: translateZ(0);

    @include res(sp) {
        writing-mode: horizontal-tb;
        font-size: 1.2rem;
    }
}

.StickyButton__icon {
    display: block;
    height: 20px;
    width: 20px;
    position: relative;
    margin: 4px auto 0;

    @include res(sp) {
        margin-top: 0;
        margin-left: 4px;
        margin-right: 0;
    }

    .Icon {
        transform: translateZ(0);
    }
}

.StickyButton--personal {
    .ButtonBg {
        &:before {
            background: $color-blue-gradation;
        }
    }

    .StickyButton__label {
        @include text-blue-gradation;
    }

    .Icon {
        background: $color-blue-gradation;
    }

}

.StickyButton--business {
    .ButtonBg {
        &:before {
            background: $color-purple-gradation;
        }
    }

    .StickyButton__label {
        @include text-purple-gradation;
    }

    .Icon {
        background: $color-purple-gradation;
    }

}
