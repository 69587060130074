@use "../global" as *;
@use "../mixin" as *;

.PageBackButton {
    display: inline-flex;
    align-items: center;

    .Icon--circle-button {
        &:before {
            transition: opacity 0.15s ease-out;
        }
    }

    &:hover {
        .Icon--circle-button {
            &:before {
                opacity: 1;
            }

            svg path {
                stroke: $color-blue;
            }
        }

        .PageBackButton__label {
        transform: translateX(-3px);
        }
    }
}

.PageBackButton__label {
    margin-left: 16px;
    position: relative;
    transition: transform 0.25s ease-out;
}