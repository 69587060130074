@use "../global" as *;
@use "../mixin" as *;


/*********************************
	Base
*********************************/

.ListButton {
    border-bottom: 1px solid $color-text-gray;
}

.ListButtonLink {
    display: block;
    padding: 22px 24px;
    position: relative;
    transition: 0.08s background-color ease-out;
}

.ListButtonLink {
    .Icon {
        display: block;
        position: absolute;
        right: 8px;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 24px;
        height: 24px;
    }
}

.ListButtonLink {
    .Text {
        transition: 0.08s color ease-out;
    }
}

.ListButtonLink {
    &:hover:not(.ListButtonLink--no-link) {
        background-color: $color-light-blue;

        .Text {
            color: $color-blue;
        }

        &.ListButtonLink--external::before {
            color: $color-blue;
        }

        .Icon {
            animation: arrowmove 0.2s ease-out;

            path{
                stroke: $color-blue;
            }
        }
    }

    @include res(sp) {
        padding: 16px 8px;
    }
}

.ListButtonLink__title {
    align-items: center;

    .Text {
        text-overflow: ellipsis;
        padding-right: 24px;
        white-space: nowrap;
        overflow: hidden;
    }

    .Badge {
        margin-right: 8px;
        min-width: 0;
    }
}

/*********************************
	no Link
*********************************/

.ListButtonLink--no-link {
    pointer-events: none;

    .ListButtonLink__title .Text {
        padding-right: 0;
        overflow: inherit;
        white-space:pre-wrap;
        min-width: 0;
    }
}
