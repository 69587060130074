@use "../global" as *;
@use "../mixin" as *;

.Point {
  display: flex;
  flex-direction: column;
  gap: 100px;

  @include res(sp) {
    gap: 36px;
  }
}

.PointItem {
  display: flex;

  @include res(sp) {
    flex-direction: column;
  }
}

.PointItemIndex {
  width: 25%;
  background: $color-purple-gradation;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    opacity: 0.4;

  @include res(sp) {
    width: 100%;
  }
}
.PointItemIndex__label {
  font-size: 3.2rem;
  font-weight: 700;
  text-align: center;
  @include res(sp) {
    font-size: 2.4rem;
  }
}
.PointItemIndex__value {
  font-size: 160px;
  text-align: center;
  font-weight: 700;
  font-family: 'Comfortaa';
  margin-top: 12px;

  @include res(sp) {
    font-size: 100px;
  }
}
.PointItem__content {
  width: 75%;

  @include res(sp) {
    width: 100%;
  }
}