@use "../global" as *;
@use "../mixin" as *;

.Video {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
    background-color: $color-base-blue;
    border-radius: $border-radius-md;

    iframe {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
        width: calc(100% - 32px);
        height: calc(100% - 32px);
    }
}

.Video--file {
    padding: 16px;

    video {
        width: 100%;
        height: 100%;
    }
}