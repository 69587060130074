@use "../global" as *;
@use "../mixin" as *;


/*********************************
	RoundBlock--text
*********************************/

// topページ Plan・Price > 料金例
.RectangleBlock--plan-price {
    border: 2px solid #F2F2F2;
    padding: 24px 24px 32px;
    height: 100%;
}


// topページ Feature
.RectangleBlock--feature {
    background-color: $color-bg-light-gray;
    padding: 24px 24px 32px;
    height: 100%;
}
