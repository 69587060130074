@use "../mixin" as *;
@use '../global' as *;

.Row--gap-xs {
    gap:$Gap--xs;
}

.Row--gap-sm {
    gap:$Gap--sm;
}

.Row--gap-md {
    gap:$Gap--md;
}

.Row--gap-lg {
    gap:$Gap--lg;
}

.Row--gap-xl {
    gap:$Gap--2l;
}

.Row--gap-xxl {
    gap:$Gap--3l;
}

.Row--gap-3l {
    gap:$Gap--4l;
}

.Row--gap-xxl-md {
    gap:$Gap--3l $Gap--md;
}


@include res(tb) {
    .Row--tb-gap-md {
        gap:$Gap--md;
    }

    .Row--tb-gap-lg {
        gap:$Gap--lg;
    }

    .Row--tb-sm-gap-lg {
        gap:$Gap--lg;
    }
}

@include res(sp) {
    .Row--sp-gap-0 {
        gap:0;
    }

    .Row--sp-gap-sm {
        gap:$Gap--sm;
    }

    .Row--sp-gap-md {
        gap:$Gap--md;
    }

    .Row--sp-gap-lg {
        gap:$Gap--lg;
    }

    .Row--sp-gap-xxl-md {
        gap:$Gap--3l $Gap--md;
    }

    .Row--sp-gap-lg-md {
        gap:$Gap--lg $Gap--md;
    }

    .Row--sp-gap-xl {
        gap:$Gap--2l;
    }
}