@use "../global" as *;
@use "../mixin" as *;

.SpMenuButton {
    width: 0;
    height:40px;
    border-radius: 8px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin:auto 0;
    visibility: hidden;
    opacity: 0;
    cursor: pointer;
    transform: translateX(4px);
    transition: all .2s  ease-out;
    z-index: $HeaderMenu;
    overflow: hidden;

    &:hover span {
        &:nth-of-type(1) {
            transform: translateY(-2px);
        }

        &:nth-of-type(3) {
            transform: translateY(2px);
        }
    }

    &.SpMenuButton--open span {
        &:nth-of-type(1) {
            transform: translateY(10px) rotate(-45deg);
            top:10px;
        }

        &:nth-of-type(2) {
            opacity: 0;
        }

        &:nth-of-type(3){
            transform: translateY(-10px) rotate(45deg);
            top:30px;
        }
    }


    @include res(header-sm) {
        transform: translateX(0);
        visibility: visible;
        opacity: 1;
        width: 40px;
    }
}

.SpMenuButton span {
    display: inline-block;
    transition: all .15s ease-out;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    height: 4px;
    border-radius: 2px;
    background: $color-blue-gradation;
    width: 30px;

    &:nth-of-type(1) {
        top:10px;
    }

    &:nth-of-type(2) {
        top:20px;
    }

    &:nth-of-type(3) {
        top:30px;
    }

}

