@use "../mixin" as *;

$ContensInner--xs : 16px;
$ContensInner--sm : 32px;
$ContensInner--md : 40px;
$ContensInner--lg : 56px;
$ContensInner--xl : 90px;
$ContensInner--xxl : 150px;
$ContensInner--3l : 240px;

/*********************************
	Size
*********************************/

.ContensInner--sm {
  padding: $ContensInner--xs 0;
}

.ContensInner {
  padding: $ContensInner--lg 0;
}

.ContensInner--xl {
  padding: $ContensInner--xl 0;
}

.ContensInner--xxl {
  padding: $ContensInner--xxl 0;
}

.ContensInner--narrow {
  padding-top: $ContensInner--md;
  padding-bottom:$ContensInner--sm;
}

.ContensInner--pagemv {
  padding: $ContensInner--xxl 0;

  @include res(sp) {
    padding-top: $ContensInner--xl;
  }
}

.ContensInner--pagemv-product {
  padding-top: $ContensInner--xxl;
  padding-bottom: 180px;

  @include res(sp) {
      padding-top: $ContensInner--xl;
      padding-bottom: 120px;
  }
}

.ContensInner--footer {
  padding-top: 200px;
  padding-bottom: 0;

  @include res(sp) {
    padding-top: 120px;
  }
}

.ContensInner--footer-no-wave {
  padding-top: $ContensInner--md;
}

@include res(sp) {
  .ContensInner--sp-sm {
    padding: $ContensInner--sm 0;
  }

  .ContensInner--sp-lg {
    padding: $ContensInner--lg 0;
  }

  .ContensInner--sp-xl {
    padding: $ContensInner--xl 0;
  }
}

.ContensInner--mt-lg {
  margin-top: $ContensInner--lg;
}

.ContensInner--mt-sm {
  margin-top:24px ;
}

/*********************************
	Padding Top
*********************************/

.ContensInner--pt-0 {
  padding-top: 0;
}

.ContensInner--pt-sm {
  padding-top:24px ;
}

@include res(sp) {
  .ContensInner--sp-pt-0 {
    padding-top: 0;
  }
}

/*********************************
	Padding Bottom
*********************************/
.ContensInner--pb-xl {
  padding-bottom: $ContensInner--xl;
}

.ContensInner--pb-xxl {
  padding-bottom: $ContensInner--xxl;
}

.ContensInner--pb-3l {
  padding-bottom: $ContensInner--3l;
}

.ContensInner--business-3 {
  padding-top: 64px;
  padding-bottom: 116px;

  @include res(sp) {
    padding-top: 30px;
    padding-bottom: 64px;
  }
}
