@use "../global" as *;
@use "../mixin" as *;

.Section {
    position: relative;
}


.Section--mt-lg {
    margin-top: 56px;
}

.Section--mt-xxl {
    margin-top: 120px;
}

@include res(tb){
    .Section--tb-mt-lg {
        margin-top: 56px;
    }
}


.SectionHeader {
    +.SectionContent {
        margin-top: 40px;
    }

    +.SectionContent--mt-sm {
        margin-top: 16px;
    }

    +.SectionContent--mt-md {
        margin-top: 32px;
    }

    +.SectionContent--mt-lg {
        margin-top: 80px;
    }

    +.SectionContent--mt-xl {
        margin-top: 120px;
    }

    +.SectionContent--mt-64 {
        margin-top: 64px;
    }

    +.SectionContent--my-page-top-accuracy {
        margin-top: 64px;
        padding-bottom: 64px;
        border-bottom: 2px solid #F2F2F2;
    }

    @include res(tb){
        +.SectionContent--sp-mt-md {
            margin-top: 40px;
        }
    }

    @include res(tb){
        & + .SectionContent--tb-mt-lg {
            margin-top: 40px;
        }
    }

    @include res(sp){
        & +.SectionContent--sp-mt-64{
            margin-top: 32px;
        }
    }

    @include res(sp){
        & + .SectionContent--sp-my-page-top-accuracy {
            margin-top: 32px;
            padding-bottom: 32px;
        }
    }

}

/*********************************
	SectionContent
*********************************/

.SectionContent {
    position: relative;
}

.SectionContent {
    & + .SectionContent {
        margin-top: 80px;
    }
    & + .SectionContent--mt-md {
        margin-top: 60px;
    }
    
    & + .SectionContent--mt-64{
        margin-top: 64px;
    }
        
    & + .SectionContent--mt-xl {
        margin-top: 220px;
    }

    & + .SectionContent--my-page-top-accuracy {
        margin-top: 64px;
        padding-bottom: 64px;
        border-bottom: 2px solid #F2F2F2;
    }

    @include res(tb-sm){
        & + .SectionContent--tb-sm-mt-lg {
            margin-top: 120px;
        }
    }

    @include res(sp){
        & +.SectionContent--sp-mt-64{
            margin-top: 32px;
        }
    }
    @include res(sp){
        & + .SectionContent--sp-my-page-top-accuracy {
            margin-top: 32px;
            padding-bottom: 32px;
        }

        & + .SectionContent--sp-my-page-top-accuracy {
            margin-top: 32px;
            padding-bottom: 32px;
        }
    }

}

.SectionDescription {
    margin-top: 16px;
}

/*********************************
	SectionFooter
*********************************/

.SectionFooter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.SectionFooter {
    margin-top: 56px;

    @include res(tb){
        margin-top: 40px;
    }
}

.SectionFooter-mt-lg {
    margin-top: 90px;
}
.SectionFooter-mt-xl {
    margin-top: 110px;
}

@include res(sp){
    .SectionFooter--sp-mt-0 {
        margin-top: 0;
    }
    .SectionFooter-sp-mt-lg {
        margin-top: 60px;
    }
}

.SectionFooter__caption {
    margin-bottom: 24px;
}

.RectButton + .SectionFooter__caption {
    margin-bottom: 0;
    margin-top: 24px;
}

/*********************************
	Background
*********************************/

.Section--bg-light-blue {
    &:before {
        display: block;
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-color: $color-light-blue;
        z-index: -1;
    }
}

.Section--bg-light-purple {
    &:before {
        display: block;
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-color: rgb(241, 224, 248, 0.2);
        z-index: -1;
    }
}


/*********************************
	Others
*********************************/

.SectionTitle--underline-purple {
    position: relative;

    &::after {
        display: block;
        content: "";
        height: 4px;
        width: 80px;
        position: absolute;
        bottom: -12px;
        left: 0;
        right: 0;
        margin: 0 auto;
        background-color: $color-purple;
    }
}