@use "../global" as *;
@use "../mixin" as *;

.Stack {
    display: grid;
    grid-template-columns: 100%;

    &.Stack--gap-sm {
        grid-gap: 8px;
    }

    &.Stack--gap-md {
        grid-gap: 16px;
    }

    &.Stack--gap-lg {
        grid-gap: 24px;
    }

    &.Stack--gap-xl {
        grid-gap: 32px;
    }

    &.Stack--gap-xxl {
        grid-gap: 80px;
    }
    
    &.Stack--center{
        justify-items: center;
    }
}

.Stack__item {
    & +.Stack__item--mt-xs {
        margin-top: 4px;
    }

    & +.Stack__item--mt-sm {
        margin-top: 8px;
    }

    & +.Stack__item--mt-md {
        margin-top: 24px;
    }

    & +.Stack__item--mt-lg {
        margin-top: 48px;
    }

    & +.Stack__item--mt-xl {
        margin-top: 64px;
    }

    & +.Stack__item--mt-xxl {
        margin-top: 80px;
    }

    & +.Stack__item--mt-16{
        margin-top: 16px;
    }

    & +.Stack__item--mt-32 {
        margin-top: 32px;
    }

    @include res(sp){
        & +.Stack__item--sp-mt-xs {
            margin-top: 4px;
        }

        & +.Stack__item--sp-mt-sm {
            margin-top: 8px;
        }

        & +.Stack__item--sp-mt-md {
            margin-top: 24px;
        }

        & +.Stack__item--sp-mt-lg {
            margin-top: 48px;
        }

        & +.Stack__item--sp-mt-xl {
            margin-top: 64px;
        }

        & +.Stack__item--sp-mt-xxl {
            margin-top: 80px;
        }

        & +.Stack__item--sp-mt-32 {
            margin-top: 32px;
        }
        
        & +.Stack__item--sp-mt-40 {
            margin-top: 40px;
        }
    }
}

.Stack {
    &.Stack--page-top-price-supplement {
        background-color: #F2F2F2;
        padding: 24px 24px 32px;
    }
}