@use "../global" as *;
@use "../mixin" as *;

.Badge {
    border: 1px solid $color-text-black;
    border-radius: 20px;
    padding: 3px 8px;
    display: inline-block;
    flex-shrink: 0;

    &::before {
        margin: auto;
        font-size: 12px;
        color: $color-text-gray;
        display: flex;
        align-items: center;
        transition: 0.1s color ease-out;
    }
}

/*********************************
	Type
*********************************/

.Badge--external {
    &:before {
        content: "外部サイト";
    }
}

.Badge--pdf {
    &:before {
        content: "pdf";
    }
}

/*********************************
	News
*********************************/
.Row--grid-news,.Row--grid-usecase {
    .Badge {
        margin-top: 2px;
    }
}