@use "../global" as *;
@use "../mixin" as *;

.List {
    display: grid;
    gap: $Gap--md;
}

.List--ml-20 {
    margin-left: 20px;
}

.List--gap-lg { 
    display: grid;
    gap: $Gap--lg;
}

.List__item {
    display: flex;
    @include text-md;

    .Text--bold {
        display: contents;
    }

    &:before {
        display: inline-block;
        content: "";
        flex-shrink: 0;
    }
}

/*********************************
	Circle List
*********************************/

.List--circle {
    gap: $Gap--xs;

    &.List--circle-page-top {
        gap: $Gap--md;
    }

    &.List--circle-page-top-price-supplement {
        gap: $Gap--xxs;
        margin-top: 4px;
    }


    .List__item {
        display: block;
        position: relative;
        padding-left: 16px;

        &:before {
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background-color: $color-text-black;
            margin-right: 8px;
            margin-top: 0.7em;
            position: absolute;
            left: 0px;
        }
    }
}

/*********************************
	Number List
*********************************/

.List--num {
    counter-reset: count 0;

    .List__item {
        display: block;
        text-indent: -24px;
        padding-left: 24px;

        &:before {
            content: counter(count) ". ";
            counter-increment: count 1;
            font-size: 16px;
            display: inline-block;
            float: left;
            margin-right: 24px;
        }

        .List--num {
            margin-top: 16px;

            .List__item {
                text-indent: -36px;
                padding-left: 36px;
                position: relative;

                &:before {
                    content:"("counter(count) ") ";
                    counter-increment: count 1;
                    font-size: 16px;
                    margin-right: 36px;
                }
            }

            .List--num {
                .List__item {
                    text-indent: -36px;
                    padding-left: 36px;

                    &:before {
                        content:counter(count);
                        counter-increment: count 1;
                        font-size: 13px;
                        margin-right: 36px;
                        position: relative;
                        left: 5px;
                        top: 3px;
                    }

                    &:after {
                        content: "";
                        display: block;
                        width: 16px;
                        height: 16px;
                        border-radius: 50%;
                        border: 1px solid $color-text-black;
                        position: absolute;
                        left: 0;
                        top: 5px;
                        margin: auto 0;
                    }
                }
            }
        }
    }

    .List__item--lg {
        display: block;
        text-indent: -28px;
        padding-left: 36px;

        @include res(pc){
            text-indent: -24px;
            padding-left: 32px;
        }

        &:before {
            content: '';
            display: inline-block;
            width: 28px;
            height: 28px;
            background-image: url(/assets/img/top/icon-done-outline-blue.svg);
            background-size: contain;
            font-size: 24px;
            float: left;
            position: relative;
            top: 4px;
            left: -36px;

            @include res(pc){
                font-size: 20px;
                width: 24px;
                height: 24px;
                left: -32px;
            }
        }
    }
}

/*********************************
	Number-none List
*********************************/

.List--num-none {

    .List__item {
        display: block;
        position: relative;
        padding-left: 32px;

        &:before {
            display: none;
        }

        .ListNum {
            position: absolute;
            left: -32px;
            top: 0;
        }

        .List--num-none  {
            margin-top: 16px;
            margin-left: 0px;

            .List__item {
                padding-left: 56px;
            }

            .ListNum {
                left: -64px;
            }

            .List--num-none  {
                .List__item {
                    padding-left: 28px;
                }

                .ListNum {
                    left: -28px;
                }
            }
        }

        .List--circle-num {
            .List__item {
                padding-left: 28px;
            }

            .ListNum {
                left: -28px;
            }
        }
    }
}

/*********************************
	Check List
*********************************/
.List--check-blue,
.List--check-purple {
    .List__item {
        align-items: center;

        &:before {
            margin-right: 16px;
            width: 20px;
            height: 20px;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
        }
    }
}

.List--check-blue {
    .List__item {
        &:before {
            background-image: url(/assets/img/icon/icon-check-gradation-blue.svg);
        }
    }
}

.List--check-purple {
    .List__item {
        &:before {
            background-image: url(/assets/img/icon/icon-check-gradation-purple.svg);
        }
    }
}


/*********************************
	mike List
*********************************/
.List--mike {
    .List__item{
        &:before {
            width: 20px;
            height: 20px;
            background-image: url(/assets/img/icon/icon-mike.svg);
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            margin-right: 8px;
            margin-top: 3px;
        }
    }
}

/*********************************
	supplement List
*********************************/

.supplement {
    @include text-sm;
    color: $color-text-gray;
    padding-left: 16px;
    position: relative;

    &:before {
        content:"※";
        display: inline-block;
        position: absolute;
        left: 0;
    }
}


/*********************************
	gap
*********************************/

.List--gap-1px {
    gap: 1px;
}

.List--gap-xs {
    gap: $Gap--xs;
}

.List--gap-sm {
    gap: $Gap--sm;
}

.List--gap-lg {
    gap: $Gap--lg;
}

@include res(sp) {
    .List--sp-gap-md {
        gap: $Gap--md;
    }
}